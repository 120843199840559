import { postCustomerLoyaltyPoint } from "api/customer-repository";
import { callGetApiOrderDetails, confirmOrder } from "api/order-repository";
import { PINNED_PRICING_CACHE_KEY, useApp } from "contexts/app";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { find, sumBy, toNumber } from "lodash";
import { useMemo, useRef, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useCartContext } from "..";
import {
  fulfillmentAsyncOrder,
  handlePrintData,
  paymentMethodsDefault,
  pricingDefault,
  processingDiscover,
} from "../cart-helpers.service";
import { concatString, ShoppingCartModel } from "../utils";
import { ModalConfirmPartialPayment } from "./components/modalConfirmPartialPayment";
import { ModalCreateOrderOnlineSuccess } from "./components/modalCreateOrderOnlineSuccess";
import { ModalCreateOrderSuccess } from "./components/modalCreateOrderSuccess";
import { Customers } from "./customers";
import { PaymentSummary } from "./paymentSummary";
import { PricingList } from "./pricingList";
import { IS_OPEN_PRICING_LIST } from "utils/whiteListShop";
import { Notes } from "./notes";
import { Payment } from "./Payments/payment";
import { Delivery } from "./delivery";
import { VAT, VATContext, defaultTaxData } from "./vat";
import "./styles.css";
import { useHotkeysContext } from "react-hotkeys-hook";
import {
  BANK_QR_MIN_AMOUNT,
  HOTKEY_SCOPES,
  MOMO_MIN_AMOUNT,
  SessionScreenType,
  PAYME_MIN_AMOUNT,
} from "models/constants";
import { SYSTEM_GUEST_EMAIL } from "infrastructures/utils";
import { NoteAttributes } from "./noteAttributes";
import {
  getCheckoutSession,
  submitCheckoutSession,
  updateCheckoutSession,
} from "api/checkout-repository";
import { useShift } from "contexts/shift/shift-context";
import { ModalQRPayment } from "./components/modalQRPayment";
import { ModalCreateOrderSuccessQR } from "./components/modalCreateOrderSuccessQR";
import { CONSTANT_LINKS, getConstantLinkDetail } from "models/links";
import { GetCurrentShift } from "contexts/shift/utils";
import { PaymentQrMethods } from "models/payment-methods-model";
import { ModalPOSPayment } from "./components/modalPOSPayment";

export const saleModes = {
  inPoint: "inPoint",
  delivery: "delivery",
};

export const RightCart = () => {
  const refPaymentSummary = useRef(null);
  const refRightMainCover = useRef(null);
  const appContext = useApp();
  const { shift } = useShift();
  const [openPopupVat, setOpenPopupVat] = useState(false);
  const [taxRequired, setTaxRequired] = useState(false);
  const [eInvoice, setEInvoice] = useState(null);
  const [openRemoveVat, setOpenRemoveVat] = useState(false);
  const { shop_info: shopInfo, shop_setting, defaultPricingList } = useApp();
  const { showGlobalToast } = useLayoutContext();
  const [showModalPaymentPartial, setShowModalPaymentPartial] = useState(false);
  const [hasQRPayment, setHasQRPayment] = useState(false);
  const [hasPOSMethod, setHasPOSMethod] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [showEditAddress, setShowEditAddress] = useState(false);
  const [showAttributeDetail, setShowAttributeDetail] = useState(false);
  const { enableScope } = useHotkeysContext();
  const intl = useIntl();
  const {
    current_shopping_cart,
    setLoadingCart,
    updateCurrentCart,
    isSubmitOrderWaiting,
    setIsSubmitOrderWaiting,
    showModalSuccess,
    setShowModalSuccess,
    showModalSuccessQR,
    setShowModalSuccessQR,
    showQRPaymentModal,
    setShowQRPaymentModal,
    signalRData,
    showPOSPaymentModal,
    setShowPOSPaymentModal,
  } = useCartContext();

  useEffect(() => {
    if (current_shopping_cart)
      updateCurrentCart({
        taxData: { ...current_shopping_cart?.taxData, eInvoice },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_shopping_cart?.key, eInvoice]);

  useEffect(() => {
    getEinvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEinvoice = async () => {
    try {
      const location = appContext.current_location;
      let url = `/call/com_api/einvoice_settings/location_settings?location_id=${location.id}&channel=pos`;
      const response = await appContext.callAPI("get", url);
      const data = response.data ?? [];
      setEInvoice(data);
    } catch (error) {}
  };

  const resetCart = () => {
    let new_cart = new ShoppingCartModel();
    let pinnedPricingLists = JSON.parse(
      localStorage.getItem(PINNED_PRICING_CACHE_KEY) || "[]",
    );
    const pinned = pinnedPricingLists?.find(
      (pl) =>
        pl.userId === appContext?.auth?.user?.id &&
        pl.locationId === appContext?.current_location?.id,
    );

    const isPinnedActive =
      pinned && defaultPricingList?.some((pl) => pl.id === pinned.id);
    updateCurrentCart({
      ...new_cart,
      title: current_shopping_cart.title,
      pricingApplied: isPinnedActive ? pinned : pricingDefault,
      payment_methods: paymentMethodsDefault(appContext.shop_setting),
      taxData: { ...defaultTaxData, eInvoice },
      salesMan: {
        id: toNumber(appContext.auth.user.id),
        name: appContext.auth.user.name,
      },
    });
  };

  const payment_methods_active = useMemo(() => {
    setHasPOSMethod(
      current_shopping_cart?.payment_methods.some(
        (method) => method.enable && method.isPOS,
      ),
    );
    setHasQRPayment(
      current_shopping_cart?.payment_methods.some(
        (method) => method.enable && method.isQR,
      ),
    );
    return current_shopping_cart?.payment_methods?.filter(
      (method) => method.enable,
    );
  }, [current_shopping_cart?.payment_methods]);

  // tổng tiền khách đưa
  const totalMoneyPaidByCustomer = useMemo(() => {
    if (payment_methods_active?.length) {
      return sumBy(payment_methods_active, (method) => method.amount);
    } else {
      return 0;
    }
  }, [payment_methods_active]);

  const isCod = useMemo(() => {
    return payment_methods_active?.find((method) => method.type === "cod")
      ?.enable;
  }, [payment_methods_active]);

  const validOrderBeforeSubmit = (isPaymentOnline = false) => {
    try {
      const location = appContext.current_location;
      if (!location) {
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.Vui lòng chọn chi nhánh bán hàng`,
            defaultMessage: "Vui lòng chọn chi nhánh bán hàng",
          }),
        );
        return false;
      }
      if (!current_shopping_cart?.salesMan?.id) {
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.Vui lòng chọn nhân viên bán hàng`,
            defaultMessage: "Vui lòng chọn nhân viên bán hàng",
          }),
        );
        return false;
      }
      if (isCod && !current_shopping_cart?.customer) {
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.Vui lòng chọn khách hàng`,
            defaultMessage: "Vui lòng chọn khách hàng",
          }),
        );
        return false;
      }
      const hasEmptyLots = current_shopping_cart?.lineItems?.find(
        (line) => line.lotSupport && !line.lots?.length,
      );
      if (!current_shopping_cart.isShipping && hasEmptyLots) {
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.Lô không được bỏ trống`,
            defaultMessage: "Lô không được bỏ trống",
          }),
        );
        return false;
      }
      const isInvalidQuantity = current_shopping_cart?.lineItems?.some(
        (product) => {
          return product?.lotSupport
            ? product.lots?.reduce((prev, curr) => prev + curr.quantity, 0) !==
                product.quantity
            : false;
        },
      );

      if (isInvalidQuantity && !current_shopping_cart?.isShipping) {
        showGlobalToast(
          "error",
          "Số lượng của lô phải bằng số lượng cần giao hàng",
        );
        return;
      }
      if (!payment_methods_active?.length && !isPaymentOnline) {
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.Vui lòng chọn phương thức thanh toán`,
            defaultMessage: "Vui lòng chọn phương thức thanh toán",
          }),
        );
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  };

  async function postOrderPayment(
    orderData,
    isConfirmOrder = true,
    isFulfillAsync = true,
  ) {
    const mergedOrderData = {
      ...orderData,
      ...(!current_shopping_cart.isShipping && {
        orderProducts: orderData?.orderProducts?.map((p, index) => ({
          ...p,
          lots: current_shopping_cart.lineItems?.find(
            (l) => l.variantId === p.productVariantsId && l.seq === index,
          )?.lots,
        })),
      }),
    };
    if (
      shop_setting.isConfirmOrderEnable &&
      isConfirmOrder &&
      orderData?.orderId
    ) {
      confirmOrder(appContext.callAPI, orderData?.orderId);
    }
    if (
      !current_shopping_cart.isShipping &&
      isFulfillAsync &&
      orderData?.orderId
    ) {
      fulfillmentAsyncOrder(
        mergedOrderData,
        current_shopping_cart.isShipping,
        appContext,
        showGlobalToast,
      );
    }
  }

  const sendOrder = async ({
    isPaymentOnline = false,
    paymentDeviceName = "",
    posPaymentType = 1,
  } = {}) => {
    if (!isSubmitOrderWaiting) {
      try {
        let _current_shift = await GetCurrentShift(
          appContext,
          appContext.current_location?.id,
        );
        let _status = "empty";

        if (_current_shift) {
          // kiểm tra ca còn hạn hay không
          const now = new Date();
          const shiftOpenAt = new Date(_current_shift.openAt);
          const hours = Math.floor(Math.abs(now - shiftOpenAt) / 36e5);
          const isSameDay =
            now.getFullYear() === shiftOpenAt.getFullYear() &&
            now.getMonth() === shiftOpenAt.getMonth() &&
            now.getDate() === shiftOpenAt.getDate();
          // - nếu còn hạn:
          if (!isSameDay || hours >= 18) _status = "outdated";
          else _status = "ready";
        }
        if (appContext.shop_setting.shiftEnable && _status !== "ready") {
          showGlobalToast(
            "error",
            intl.formatMessage({
              id: `notifications.Vui lòng mở ca để tiếp tục bán hàng`,
              defaultMessage: "Vui lòng mở ca để tiếp tục bán hàng",
            }),
          );
          return;
        }
        const activeQRMethod = payment_methods_active.find(
          (m) => m.isQR && m.enable,
        );
        const activePOSMethod = payment_methods_active.find(
          (m) => m.isPOS && m.enable,
        );
        if (hasQRPayment) {
          if (
            activeQRMethod.paymentInstructionTypeId ===
              PaymentQrMethods.momo_qr &&
            current_shopping_cart?.summaries?.total_payment < MOMO_MIN_AMOUNT
          ) {
            return;
          }
          if (
            activeQRMethod.paymentInstructionTypeId !==
              PaymentQrMethods.momo_qr &&
            current_shopping_cart?.summaries?.total_payment < BANK_QR_MIN_AMOUNT
          ) {
            return;
          }
        }
        if (hasPOSMethod) {
          if (
            current_shopping_cart?.summaries?.total_payment <= PAYME_MIN_AMOUNT
          ) {
            return;
          }
        }
        const sessionId = current_shopping_cart?.id;
        if (!sessionId) {
          return;
        }
        setIsSubmitOrderWaiting(true);
        if (!validOrderBeforeSubmit(isPaymentOnline)) {
          setIsSubmitOrderWaiting(false);
          return;
        }
        let attributes = current_shopping_cart.noteAttributes || null;
        let isHasShiftManager = false;
        if (shift.current_shift?.shiftManager?.id) {
          if (!attributes) attributes = [];
          let idx = attributes.findIndex(
            (x) => x.key === "x-haravan-pos-supervisor",
          );
          if (idx === -1) {
            attributes.push({
              key: "x-haravan-pos-supervisor",
              value: shift.current_shift.shiftManager.id + "",
            });
          } else {
            attributes[idx] = {
              key: "x-haravan-pos-supervisor",
              value: shift.current_shift.shiftManager.id + "",
            };
          }
          isHasShiftManager = true;
        }
        const updateRes = await updateCheckoutSession(
          appContext.callAPI,
          sessionId,
          {
            ...(hasQRPayment
              ? {
                  paymentMethodId:
                    current_shopping_cart?.paymentMethods?.find(
                      (method) =>
                        method.sysTypeId ===
                        activeQRMethod.paymentInstructionTypeId,
                    )?.id || 0,
                  paymentMethodName: null,
                }
              : hasPOSMethod
                ? {
                    paymentMethodId:
                      current_shopping_cart?.paymentMethods?.find(
                        (method) =>
                          method.sysTypeId ===
                          activePOSMethod.paymentInstructionTypeId,
                      )?.id || 0,
                    paymentMethodName: null,
                  }
                : isCod
                  ? {
                      paymentMethodId:
                        current_shopping_cart?.paymentMethods?.find(
                          (method) => method.sysTypeId === 1,
                        )?.id || 0,
                      paymentMethodName: null,
                    }
                  : {
                      paymentMethodName:
                        current_shopping_cart?.payment_methods?.find(
                          (method) => method.enable && !method.isHidden,
                        )?.name || "Tiền mặt",
                    }),
            sendReceipt: appContext.shop_setting?.isSendEmailConfirm,
            sendNotify: true,
            note: current_shopping_cart.orderNote,
            ...(isHasShiftManager && {
              attributes: attributes,
            }),
            ...(current_shopping_cart?.reOrderLineItems && {
              lineItems: current_shopping_cart?.lineItems?.map(
                (line, index) => ({
                  id: line.id,
                  seq: index,
                  quantity: line.quantity,
                }),
              ),
            }),
          },
        );
        if (updateRes && updateRes.total !== current_shopping_cart.total) {
          setIsSubmitOrderWaiting(false);
          processingDiscover({
            currentCart: { ...current_shopping_cart },
            newCart: updateRes,
            showGlobalToast,
            appContext,
            methodDefaults: current_shopping_cart.payment_methods,
            intl,
          }).then((res) => {
            if (current_shopping_cart.id === updateRes.id) {
              updateCurrentCart({ ...res });
            }
          });
          showGlobalToast(
            "default",
            intl.formatMessage({
              id: `notifications.Data changed`,
              defaultMessage: `notifications.Data changed`,
            }),
          );
          return;
        }
        const errorCoupons = current_shopping_cart?.discountAllocations?.reduce(
          (coupons, cur) => {
            const isExist = updateRes?.discountAllocations?.find(
              (d) => d.name === cur?.name,
            );
            if (cur?.name && !isExist) {
              coupons.push(cur.name);
            }
            return coupons;
          },
          [],
        );
        if (updateRes?.hasError || !!errorCoupons?.length) {
          const orderErrors = [...updateRes.errors, ...updateRes.userErrors];
          const errorMsg = !!errorCoupons?.length
            ? `Mã ${errorCoupons?.join(", ")} không hợp lệ`
            : orderErrors[0]?.message || "Có gì đó không ổn";
          setIsSubmitOrderWaiting(false);
          processingDiscover({
            currentCart: { ...current_shopping_cart },
            newCart: updateRes,
            showGlobalToast,
            appContext,
            methodDefaults: current_shopping_cart.payment_methods,
            intl,
          }).then((res) => {
            if (current_shopping_cart.id === updateRes.id) {
              updateCurrentCart({ ...res });
            }
          });
          showGlobalToast(
            "error",
            intl.formatMessage({
              id: `notifications.${errorMsg}`,
              defaultMessage: errorMsg,
            }),
          );
          return;
        }
        const location = appContext.current_location;
        const currentUser = appContext.auth.user;
        const orderLines = current_shopping_cart?.lineItems;
        const summaries = current_shopping_cart?.summaries;
        if (orderLines?.length > 200) {
          showGlobalToast(
            "error",
            intl.formatMessage(
              {
                id: `notifications.Số lượng sản phẩm quá lớn: {a}/200 sản phẩm`,
                defaultMessage: "Số lượng sản phẩm quá lớn: {a}/200 sản phẩm",
              },
              { a: orderLines?.length },
            ),
          );
          setIsSubmitOrderWaiting(false);
          return;
        }
        const currentOrderLines = orderLines.map((order) => {
          return {
            ...order,
            discountAmount: +Number(
              (order.originalPrice - order.price) * order.quantity -
                order.discountPromotion * order.quantity,
            ).toFixed(4),
            lots:
              !isCod && !current_shopping_cart?.isShipping ? order.lots : null,
          };
        });

        //Redeem model
        let redeemModel = {};
        if (
          appContext.shop_loyalty?.enabled &&
          current_shopping_cart?.loyalty?.redeemConfig &&
          current_shopping_cart?.loyalty_program
        ) {
          const { redeemConfig } = current_shopping_cart?.loyalty;
          const loyalty_program = current_shopping_cart.loyalty_program;

          redeemModel = {
            redeemServiceName: redeemConfig.program_name,
            redeemPayloadId: redeemConfig.id,
            redeemDiscountType: loyalty_program.discount_type || 0,
            redeemDiscount: loyalty_program.discount || 0,
            redeemMaxPerOrder: loyalty_program.max_per_order || 0,
            redeemUsedAmount: loyalty_program.used_amount,
            redeemAmount: loyalty_program.amount,
          };
        }
        const totalDiscount = +Number(
          current_shopping_cart?.orderDiscountSelected?.discountAmount || 0,
        ).toFixed(4);

        const billingModel = {
          billingCompany:
            current_shopping_cart.taxData?.billingCompany ||
            concatString({
              value: [
                current_shopping_cart?.customer?.firstName,
                current_shopping_cart?.customer?.lastName,
              ],
            }),
          billingTaxCode: current_shopping_cart.taxData?.billingTaxCode || "",
          billingEmail: current_shopping_cart.taxData?.billingEmail || "",
          billingAddress: current_shopping_cart.taxData?.billingAddress || "",
          billingCountry:
            current_shopping_cart.taxData?.address?.billingCountry || "",
          billingCountryName:
            current_shopping_cart.taxData?.address?.billingCountryName || "",
          billingProvince:
            current_shopping_cart.taxData?.address?.billingProvince || "",
          billingProvinceName:
            current_shopping_cart.taxData?.address?.billingProvinceName || "",
          billingDistrict:
            current_shopping_cart.taxData?.address?.billingDistrict || "",
          billingDistrictName:
            current_shopping_cart.taxData?.address?.billingDistrictName || "",
          billingWard:
            current_shopping_cart.taxData?.address?.billingWard || "",
          billingWardName:
            current_shopping_cart.taxData?.address?.billingWardName || "",
          billingCity: current_shopping_cart.taxData?.address?.city || "",
          billingZipCode: current_shopping_cart.taxData?.address?.zipCode || "",
          eInvoiceInfo: current_shopping_cart.taxData?.eInvoiceInfo?.submit
            ? {
                company:
                  current_shopping_cart.taxData?.eInvoiceInfo?.company || false,
                name: current_shopping_cart.taxData?.eInvoiceInfo?.name || "",
                taxCode:
                  current_shopping_cart.taxData?.eInvoiceInfo?.taxCode || "",
                address:
                  current_shopping_cart.taxData?.eInvoiceInfo?.address || "",
                email: current_shopping_cart.taxData?.eInvoiceInfo?.email || "",
                save:
                  current_shopping_cart.taxData?.eInvoiceInfo?.save || false,
              }
            : null,
        };

        let orderModel = {
          ...redeemModel,
          ...billingModel,
          userId: current_shopping_cart.salesMan?.id,
          locationId: location.id,
          locationName: location.name,
          assignedLocationId: location.id,
          assignedLocationName: location.name,
          orderShippingFee: summaries.shipping_fee,
          orderNotes: current_shopping_cart.orderNote,
          note_Attributes: current_shopping_cart.noteAttributes,
          orderProducts: currentOrderLines,
          ...current_shopping_cart?.orderDiscountSelected,
          discountAmount: totalDiscount,
          isCodGateway: isCod,
          totalDiscounts: totalDiscount,
          sourceName: "pos",
          source: "pos",
          send_webhooks: true,
          fulFillMentStatusId:
            !isCod && !current_shopping_cart?.isShipping ? 1 : 0,
          fulfilledDate: null,
          paymentInstructionsId: payment_methods_active.find(
            (method) => method?.isQR,
          )?.paymentInstructionId,
        };

        const orderCustomer = { ...current_shopping_cart?.customer };
        let customerAddress = null;
        if (orderCustomer) {
          if (orderCustomer?.customerAddress?.length) {
            customerAddress = orderCustomer.customerAddress.find(
              (address) => address.isDefault,
            );
          }
          orderModel.customerId = orderCustomer.id || orderCustomer.key;
          orderModel.customerName = `${concatString({
            value: [orderCustomer.firstName, orderCustomer.lastName],
          })}`;
          orderModel.customerEmail = orderCustomer.email || "";
          orderModel.shippingFirstName = orderCustomer.firstName || "";
          orderModel.shippingLastName = orderCustomer.lastName || "";
          orderModel.shippingPhone = orderCustomer.phone || "";
          if (
            !orderModel.shippingFirstName &&
            !orderModel.shippingLastName &&
            orderModel.customerId &&
            orderModel.customerEmail !== SYSTEM_GUEST_EMAIL
          ) {
            orderModel.shippingFirstName = "--";
          }

          if (customerAddress) {
            orderModel.shippingAddress =
              customerAddress.address1 || customerAddress.address2 || "";
            orderModel.shippingProvinceCode =
              customerAddress.provinceCode || "";
            orderModel.shippingDistrictCode =
              customerAddress.districtCode || "";
            orderModel.shippingWardCode = customerAddress.wardCode || "";
            orderModel.shippingCountryCode = customerAddress.countryCode || "";
            orderModel.shippingDistrict = customerAddress.districtId || "";
            orderModel.shippingProvince = customerAddress.provinceId || "";
            orderModel.shippingCountry = customerAddress.countryId || "";
            orderModel.shippingWard = customerAddress.wardId || "";
          }
        }

        let shiftTransactions = [];
        const amountPaidByCustomerWithoutCash = sumBy(
          payment_methods_active,
          function (method) {
            if (method.type === "cash") {
              return 0;
            } else {
              return method.amount;
            }
          },
        );
        // transactions
        if (isPaymentOnline) {
          orderModel.gateway = "Thanh toán Online";
          orderModel.orderTransactions = [];
          orderModel.noteAttributes = [
            { name: "Tiền khách đưa", value: 0 },
            { name: "Tiền thừa trả khách", value: 0 },
          ];
        } else if (!isCod) {
          const isPartialPayment =
            totalMoneyPaidByCustomer - summaries.total_payment < 0;
          orderModel.totalMoneyPaidByCustomer = totalMoneyPaidByCustomer;
          orderModel.noteAttributes = [
            {
              name: "Tiền khách đưa",
              value: totalMoneyPaidByCustomer,
            },
            {
              name: "Tiền thừa trả khách",
              value: isPartialPayment
                ? 0
                : totalMoneyPaidByCustomer - summaries.total_payment,
            },
          ];

          let transactions = payment_methods_active
            .filter((method) => !method?.isQR) // có thể remove kind: "capture" cho QR method nếu muốn bỏ dòng này
            .map((method) => {
              if (!method.enable || method.amount <= 0) return null;
              if (method.type === "cash") {
                const maxCashReceive =
                  summaries.total_payment - amountPaidByCustomerWithoutCash;
                const cashAmount = Math.min(maxCashReceive, method.amount);
                return {
                  gateway: method.name,
                  transactionAmount: +Number(cashAmount).toFixed(4),
                  externalPaymentTransaction: method.transaction_id,
                  locationId: location.id,
                  createdUser: currentUser.id,
                  kind: "capture",
                };
              } else {
                return {
                  gateway: method.name,
                  transactionAmount: method.amount,
                  externalPaymentTransaction: method.transaction_id,
                  locationId: location.id,
                  createdUser: currentUser.id,
                  kind: "capture",
                };
              }
            })
            .filter((item) => item?.transactionAmount);

          shiftTransactions = payment_methods_active
            .filter((method) => !method?.isQR)
            .map((method) => {
              if (!method.enable || method.amount <= 0) return null;
              return {
                gatewayName: method.type,
                amount: method.amount,
                note: method.transaction_id,
              };
            })
            .filter((item) => item);

          orderModel.orderTransactions = transactions;
          // trường hợp do khuyến mãi = giá trị đơn hàng nên thanh toán 0d
          // Đơn hàng ghi nhận là đã thanh toán
          // nếu priceSubTotal > 0 thì vào case thanh toán sau
          if (transactions.length === 0) {
            if (summaries.total_payment === 0) {
              orderModel.PaymentStatusName = "paid";
            } else {
              //ghi PTTT mac dinh cho don hang
              const enableGateway = find(payment_methods_active, (gw) => {
                return gw.enable;
              });
              orderModel.gateway = enableGateway.name;
            }
          } else if (transactions.length !== 0) {
            //PaymentMethodName
            orderModel.gateway = transactions[0].gateway;
          }
        } else {
          orderModel.orderTransactions = [];
          //PaymentMethodName
          orderModel.gateway = "Thanh toán khi giao hàng (COD)";
          shiftTransactions.push({ gatewayName: "cod", amount: 0 });
        }

        if (!orderModel.gateway) {
          orderModel.gateway = "Tiền mặt";
        }
        const result = await submitCheckoutSession(
          appContext.callAPI,
          sessionId,
          {
            locationId: location.id,
            screenType: SessionScreenType.NEW_ORDER,
            ...(hasPOSMethod &&
              paymentDeviceName && {
                deviceUserName: paymentDeviceName,
                posPaymentType: posPaymentType,
                paymentInstructionId: activePOSMethod.paymentInstructionId,
                paymentMethodId: activePOSMethod.paymentInstructionTypeId,
              }),
          },
        );
        if (result?.errors || !result?.data) {
          if (hasPOSMethod) {
            getCheckoutSession(appContext.callAPI, sessionId).then((res) => {
              if (res && res?.order) {
                setPaymentDetails({
                  url: "error",
                  paymentInstructionId: activePOSMethod?.paymentInstructionId,
                  paymentMethodId: activePOSMethod?.paymentInstructionTypeId,
                  paymentMethodName: activePOSMethod.name,
                  paymentMethodType: activePOSMethod.type,
                  orderNumber: res?.order?.orderName,
                  orderId: res?.order?.id,
                  amount: activePOSMethod?.amount,
                  orderData: {
                    ...current_shopping_cart,
                    ...res?.order,
                    orderId: res?.order.id,
                  },
                  qrStatus: "error",
                  sessionId: res?.id,
                });
              }
            });
          }
          const orderErrors = [
            ...result?.data?.errors?.filter(
              (err) => err?.field !== "Lines" && err?.field !== "Inventory",
            ),
            ...result?.data?.userErrors,
          ];
          let notifyMsg =
            orderErrors?.length &&
            orderErrors[0]?.message ===
              "Không thể sử dụng chung với các khuyến mãi khác."
              ? "Chương trình Khách hàng thân thiết không cho phép dùng chung với Khuyến mãi khác"
              : orderErrors[0]?.message;
          const errorMsg =
            (orderErrors?.length && orderErrors[0]?.message) ||
            notifyMsg ||
            "Thanh toán không thành công";
          setIsSubmitOrderWaiting(false);
          showGlobalToast(
            "error",
            intl.formatMessage({
              id: `notifications.${errorMsg}`,
              defaultMessage: errorMsg,
            }),
          );
          return;
        } else {
          // gọi api bên loyalty để update điểm tích lũy
          // trường tổng tiền thanh toán là console. log("this.priceSubTotal()",toJS(this.priceSubTotal()));
          let dialogType = isPaymentOnline
            ? "create_order_online_success"
            : "create_order_success";
          let orderPrintData = null;
          let dataMember = null;
          const transactions = payment_methods_active
            .map((method) => {
              if (!method.enable || method.amount <= 0) return null;
              if (method.type === "cash") {
                const maxCashReceive =
                  summaries.total_payment - amountPaidByCustomerWithoutCash;
                const cashAmount = Math.min(maxCashReceive, method.amount);
                return {
                  gateway: method.name,
                  amount: +Number(cashAmount).toFixed(4),
                  externalTransactionId: method.transaction_id,
                  locationId: location.id,
                  userId: currentUser.id,
                };
              } else {
                return {
                  gateway: method.name,
                  amount: method.amount,
                  externalTransactionId: method.transaction_id,
                  locationId: location.id,
                  userId: currentUser.id,
                };
              }
            })
            .filter((item) => item?.amount);
          if (
            !isCod &&
            !!totalMoneyPaidByCustomer &&
            !hasQRPayment &&
            !hasPOSMethod
          ) {
            const transactionRes = await appContext.callAPI(
              "post",
              `/call/com_api/pos/orders/${result?.data?.order?.id}/transactions`,
              transactions,
            );
            if (!!transactionRes.errors?.length || !transactionRes.data) {
              const errorMsg = !!transactionRes?.errors?.length
                ? transactionRes.errors[0]
                : "Thanh toán không thành công";
              setIsSubmitOrderWaiting(false);
              showGlobalToast(
                "error",
                intl.formatMessage({
                  id: `notifications.${errorMsg}`,
                  defaultMessage: errorMsg,
                }),
              );
              return;
            }
          }
          if (!summaries.total_payment) {
            const confirmPaymentRes = await appContext.callAPI(
              "put",
              `/call/com_api/orders/${result?.data?.order?.id}/pay`,
            );
            if (!!confirmPaymentRes.errors?.length || !confirmPaymentRes.data) {
              const errorMsg = !!confirmPaymentRes?.errors?.length
                ? confirmPaymentRes.errors[0]
                : "Thanh toán không thành công";
              setIsSubmitOrderWaiting(false);
              showGlobalToast(
                "error",
                intl.formatMessage({
                  id: `notifications.${errorMsg}`,
                  defaultMessage: errorMsg,
                }),
              );
              return;
            }
          }
          const order = await callGetApiOrderDetails(
            appContext.callAPI,
            result?.data?.order?.id,
          );
          if (
            appContext.shop_loyalty?.enabled &&
            current_shopping_cart?.loyalty?.profile
              ?.current_membership_level !== "Chưa là thành viên"
          ) {
            dataMember = await postPoint(
              current_shopping_cart?.loyalty?.profile?.customer_profile_id,
              current_shopping_cart?.summaries?.total_payment,
            );
          }
          if (!!order?.data && !hasQRPayment && !hasPOSMethod) {
            postOrderPayment(order?.data);
          }
          orderPrintData = await handlePrintData({
            dataMember,
            data: order.data,
            model: orderModel,
            salesMan: current_shopping_cart?.salesMan,
            currentCart: current_shopping_cart,
            appContext,
            currentSession: result?.data,
          });
          setTimeout(() => {
            const qrData =
              result?.data?.nextActions?.find((action) => action.type === "Qr")
                ?.data || result?.data?.order?.paymentQrData;
            if (hasQRPayment && !!totalMoneyPaidByCustomer) {
              const qrTransaction = payment_methods_active.find((m) => m?.isQR);
              if (!!qrData) {
                setShowQRPaymentModal(true);
                setPaymentDetails({
                  url: qrData,
                  paymentInstructionId: qrTransaction?.paymentInstructionId,
                  paymentMethodId: qrTransaction?.paymentInstructionTypeId,
                  paymentMethodName: qrTransaction.name,
                  paymentMethodType: qrTransaction.type,
                  orderNumber: order.data?.orderNumber,
                  orderId: order.data?.orderId,
                  amount: qrTransaction?.amount,
                  orderData: { ...orderPrintData, ...order?.data },
                  qrStatus: "success",
                  sessionId: result?.data?.id,
                });
              } else {
                setPaymentDetails({
                  url:
                    qrTransaction?.paymentInstructionTypeId ===
                    PaymentQrMethods.momo_qr
                      ? "error"
                      : `{"Amount": 0,"Note":"error","CreatedDate":"","BankCode":"","BankName":"","BankAccountName":"","BankAccount":""}`,
                  paymentInstructionId: qrTransaction?.paymentInstructionId,
                  paymentMethodId: qrTransaction?.paymentInstructionTypeId,
                  paymentMethodName: qrTransaction.name,
                  paymentMethodType: qrTransaction.type,
                  orderNumber: order.data?.orderNumber,
                  orderId: order.data?.orderId,
                  amount: qrTransaction?.amount,
                  orderData: { ...orderPrintData, ...order?.data },
                  qrStatus: "error",
                  sessionId: result?.data?.id,
                });
                const errorMsg =
                  order?.errors?.[0] ||
                  "Có lỗi xảy ra khi lấy thông tin QR Code";
                showGlobalToast(
                  "error",
                  intl.formatMessage({
                    id: `notifications.${errorMsg}`,
                    defaultMessage: errorMsg,
                  }),
                );
              }
              setIsSubmitOrderWaiting(false);
              return;
            }
            if (hasPOSMethod && !!totalMoneyPaidByCustomer) {
              const payMENextAction = result?.data?.nextActions?.find(
                (action) => action.type === "Device",
              );
              if (!!payMENextAction) {
                setPaymentDetails({
                  url: "success",
                  paymentInstructionId: activePOSMethod?.paymentInstructionId,
                  paymentMethodId: activePOSMethod?.paymentInstructionTypeId,
                  paymentMethodName: activePOSMethod.name,
                  paymentMethodType: activePOSMethod.type,
                  orderNumber: order.data?.orderNumber,
                  orderId: order.data?.orderId,
                  amount: activePOSMethod?.amount,
                  orderData: { ...orderPrintData, ...order?.data },
                  qrStatus: "success",
                  sessionId: result?.data?.id,
                });
              } else {
                setPaymentDetails({
                  url: "error",
                  paymentInstructionId: activePOSMethod?.paymentInstructionId,
                  paymentMethodId: activePOSMethod?.paymentInstructionTypeId,
                  paymentMethodName: activePOSMethod.name,
                  paymentMethodType: activePOSMethod.type,
                  orderNumber: order.data?.orderNumber,
                  orderId: order.data?.orderId,
                  amount: activePOSMethod?.amount,
                  orderData: { ...orderPrintData, ...order?.data },
                  qrStatus: "error",
                  sessionId: result?.data?.id,
                });
                const errorMsg =
                  order?.errors?.[0] ||
                  "Đã có lỗi xảy ra, xin vui lòng thử lại.";
                showGlobalToast(
                  "error",
                  intl.formatMessage({
                    id: `notifications.${errorMsg}`,
                    defaultMessage: errorMsg,
                  }),
                );
              }
            } else {
              setShowModalSuccess({
                type: dialogType,
                data: { dataResult: order.data, orderData: orderPrintData },
              });
            }
            setIsSubmitOrderWaiting(false);
          }, 10);
        }
      } catch (e) {
        enableScope(HOTKEY_SCOPES.CART);
        setLoadingCart(false);
        setIsSubmitOrderWaiting(false);
      }
    }
  };

  async function postPoint(loyaltyId, totalMoney) {
    if (loyaltyId) {
      const result = await postCustomerLoyaltyPoint(
        appContext.callAPI,
        loyaltyId,
        totalMoney,
      );
      return result;
    }
    return null;
  }

  return (
    <>
      <div
        className="right-main--cover right-main--cover-cart d-flex flex-column justify-content-between bg-white h-100"
        ref={refRightMainCover}
      >
        <VATContext.Provider
          value={{
            openPopupVat,
            setOpenPopupVat,
            openRemoveVat,
            setOpenRemoveVat,
            taxRequired,
            setTaxRequired,
            eInvoice,
            setEInvoice,
          }}
        >
          <div className="l-segment flex-stretch scrollable-y">
            <Customers
              showEditAddress={showEditAddress}
              setShowEditAddress={setShowEditAddress}
            />
            {IS_OPEN_PRICING_LIST(appContext) ? (
              <div className="flex-auto">
                <PricingList />
              </div>
            ) : null}
            <hr className="my-0" />

            <div className="flex-auto">
              <div className="d-flex align-items-center l-pt-8">
                {!appContext?.shop_setting?.hideOrderShippingState &&
                !current_shopping_cart?.isShipping ? (
                  <Delivery />
                ) : null}
                <NoteAttributes
                  showAttributeDetail={showAttributeDetail}
                  setShowAttributeDetail={setShowAttributeDetail}
                />
                {shopInfo.taxable && !taxRequired ? <VAT /> : null}
              </div>
              {!appContext?.shop_setting?.hideOrderShippingState &&
              current_shopping_cart?.isShipping ? (
                <Delivery
                  isShippingAddress={true}
                  handleOpenModalCustomerInfo={setShowEditAddress}
                />
              ) : null}
              {shopInfo.taxable && taxRequired ? (
                <VAT isVATInfo={true} />
              ) : null}
              {showAttributeDetail ? (
                <NoteAttributes
                  isDetail={true}
                  setShowAttributeDetail={setShowAttributeDetail}
                />
              ) : null}
              <hr className="my-0 l-mt-8" />
            </div>
            <PaymentSummary ref={refPaymentSummary} />
          </div>
          <div className="l-segment flex-auto">
            <Notes />
            <Payment
              setPaymentDetails={setPaymentDetails}
              hasPOSPayment={hasPOSMethod}
              hasQRPayment={hasQRPayment}
              sendOrder={sendOrder}
              isCod={isCod}
              totalMoneyPaidByCustomer={totalMoneyPaidByCustomer}
              setShowModalPaymentPartial={setShowModalPaymentPartial}
              payment_methods_active={payment_methods_active}
              showModalSuccess={showModalSuccess}
              setShowQRPaymentModal={setShowQRPaymentModal}
            />
          </div>
        </VATContext.Provider>
      </div>
      <ModalConfirmPartialPayment
        sendOrder={sendOrder}
        show={showModalPaymentPartial}
        setShow={setShowModalPaymentPartial}
        totalMoneyPaidByCustomer={totalMoneyPaidByCustomer}
      />
      <ModalCreateOrderSuccess
        show={showModalSuccess?.type === "create_order_success"}
        setShow={(value) => {
          if (!value) {
            resetCart();
            setShowModalSuccess(null);
          }
        }}
        data={showModalSuccess?.data}
      />
      <ModalCreateOrderOnlineSuccess
        show={showModalSuccess?.type === "create_order_online_success"}
        setShow={(value) => {
          if (!value) {
            resetCart();
            setShowModalSuccess(null);
          }
        }}
        data={showModalSuccess?.data}
        resetCart={resetCart}
      />
      <ModalCreateOrderSuccessQR
        show={showModalSuccessQR?.type === "create_order_qr_success"}
        setShow={(value) => {
          if (!value) {
            resetCart();
            setShowModalSuccessQR(null);
          }
        }}
        data={showModalSuccessQR?.data}
      />
      <ModalQRPayment
        signalRData={signalRData}
        show={showQRPaymentModal}
        setShow={(
          value,
          paymentDetails,
          isResetCart = false,
          isFulfillAsync = true,
        ) => {
          if (!value) {
            postOrderPayment(paymentDetails?.orderData, true, isFulfillAsync);
            setShowQRPaymentModal(value);
            setShowModalSuccessQR({
              type: !!paymentDetails?.orderData
                ? "create_order_qr_success"
                : "",
              data: { dataResult: paymentDetails?.orderData },
            });
            if (isResetCart) {
              resetCart();
            }
          }
        }}
        changeMethodAction={(paymentDetails) => {
          if (paymentDetails?.orderId) {
            postOrderPayment(paymentDetails?.orderData, true, false);
            setShowQRPaymentModal(false);
            resetCart();
            window.open(
              `${getConstantLinkDetail(
                CONSTANT_LINKS.route_order_detail,
                paymentDetails?.orderId,
              )}?confirmPayment=true`,
              "_blank",
              "noopener,noreferrer",
            );
          }
        }}
        cancelOrderAction={(paymentDetails) => {
          if (paymentDetails?.orderId) {
            postOrderPayment(paymentDetails?.orderData, true, false);
            setShowQRPaymentModal(false);
            resetCart();
            window.open(
              `${getConstantLinkDetail(
                CONSTANT_LINKS.route_order_detail,
                paymentDetails?.orderId,
              )}?cancelOrder=true`,
              "_blank",
              "noopener,noreferrer",
            );
          }
        }}
        paymentDetails={paymentDetails}
        setPaymentDetails={setPaymentDetails}
        onTransactionCompleted={(orderData) => {
          postOrderPayment(orderData);
          setShowQRPaymentModal(false);
          setShowModalSuccess({
            type: "create_order_success",
            data: { dataResult: orderData, orderData: orderData },
          });
        }}
      />
      <ModalPOSPayment
        sendOrder={sendOrder}
        signalRData={signalRData}
        show={showPOSPaymentModal}
        setShow={(
          value,
          paymentDetails,
          isResetCart = false,
          isFulfillAsync = true,
        ) => {
          setShowPOSPaymentModal(value);
          if (!value && paymentDetails?.qrStatus !== "pending") {
            postOrderPayment(paymentDetails?.orderData, true, isFulfillAsync);
            setShowModalSuccessQR({
              type: !!paymentDetails?.orderData
                ? "create_order_qr_success"
                : "",
              data: { dataResult: paymentDetails?.orderData },
            });
            if (isResetCart) {
              resetCart();
            }
          }
        }}
        paymentDetails={paymentDetails}
        setPaymentDetails={setPaymentDetails}
        changeMethodAction={(paymentDetails) => {
          if (paymentDetails?.orderId) {
            postOrderPayment(paymentDetails?.orderData, true, false);
            setShowPOSPaymentModal(false);
            resetCart();
            window.open(
              `${getConstantLinkDetail(CONSTANT_LINKS.route_order_detail, paymentDetails?.orderId)}?confirmPayment=true`,
              "_blank",
              "noopener,noreferrer",
            );
          }
        }}
        cancelOrderAction={(paymentDetails) => {
          if (paymentDetails?.orderId) {
            postOrderPayment(paymentDetails?.orderData, true, false);
            setShowPOSPaymentModal(false);
            resetCart();
            window.open(
              `${getConstantLinkDetail(CONSTANT_LINKS.route_order_detail, paymentDetails?.orderId)}?cancelOrder=true`,
              "_blank",
              "noopener,noreferrer",
            );
          }
        }}
        onTransactionCompleted={(orderData) => {
          postOrderPayment(orderData);
          setShowPOSPaymentModal(false);
          setShowModalSuccess({
            type: "create_order_success",
            data: { dataResult: orderData, orderData: orderData },
          });
        }}
      />
    </>
  );
};
