import Icon from "component-v2/icons/icons";
import { cloneDeep, sumBy } from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { useCartContext } from "screens-v2/carts-v2";
import { PaymentMethodItem } from "./paymentMethodItem";
import { toFormattedCurrency } from "infrastructures/format-helpers";
import "./styles.css";

export const PaymentMethods = () => {
  const { current_shopping_cart, updateCurrentCart, methodDefaults } =
    useCartContext();

  const refPaymentMethods = useRef(null);

  useEffect(() => {
    if (
      !payment_methods.find((method) => method.type === "cod" && method.enable)
    ) {
      let newPaymentMethods = cloneDeep(methodDefaults);
      newPaymentMethods[0].amount =
        current_shopping_cart.summaries.total_payment;
      updateCurrentCart({ payment_methods: newPaymentMethods });
    }
  }, [current_shopping_cart.summaries.total_payment]);

  useEffect(() => {
    const isCodMethodHidden = payment_methods.find(
      (method) => method.type === "cod" && method.isHidden,
    );
    const hasChangedPaymentMethod = payment_methods_active?.filter(
      (method) => method.type === "cod",
    );
    if (current_shopping_cart?.isShipping) {
      if (!isCodMethodHidden && !hasChangedPaymentMethod?.length) {
        updateCurrentCart({
          payment_methods: payment_methods.map((method, index) => {
            return {
              ...method,
              enable: method.type === "cod" ? true : false,
              amount: 0,
              index: method.type === "cod" ? 0 : method.index,
            };
          }),
        });
      }
    } else {
      if (!isCodMethodHidden && hasChangedPaymentMethod) {
        updateCurrentCart({
          payment_methods: payment_methods.map((method, index) => {
            return {
              ...method,
              enable: method.type === "cash" ? true : false,
              amount:
                method.type === "cash"
                  ? current_shopping_cart.summaries.total_payment
                  : 0,
              index: index,
            };
          }),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_shopping_cart?.isShipping]);
  const payment_methods_active = useMemo(() => {
    return current_shopping_cart?.payment_methods
      ?.filter((method) => method.enable)
      .sort((m1, m2) => m1.index - m2.index);
  }, [current_shopping_cart?.payment_methods]);

  const nextMethodIndex = useMemo(() => {
    const hasMethodOnlyActivated = payment_methods_active?.some(
      (m) => m.hasOnly,
    );
    return current_shopping_cart?.payment_methods?.findIndex((current) =>
      hasMethodOnlyActivated
        ? !current.enable &&
          current.type !== "cod" &&
          !current.hasOnly &&
          !current.isHidden &&
          !current.isQR &&
          !current.isPOS
        : !current.enable &&
          current.type !== "cod" &&
          !current.isHidden &&
          !current.isQR &&
          !current.isPOS,
    );
  }, [payment_methods_active, current_shopping_cart?.payment_methods]);

  const isCodActive = useMemo(() => {
    return !!current_shopping_cart?.payment_methods?.find(
      (method) => method.type === "cod" && method.enable,
    );
  }, [current_shopping_cart?.payment_methods]);

  const totalMoneyPaidByCustomer = useMemo(() => {
    if (payment_methods_active?.length) {
      return sumBy(payment_methods_active, (method) => method.amount);
    } else {
      return 0;
    }
  }, [payment_methods_active]);

  const hasQRPayment = useMemo(() => {
    return current_shopping_cart?.payment_methods?.some(
      (method) => method.isQR && method.enable,
    );
  }, [current_shopping_cart?.payment_methods]);
  const hasPOSMethod = useMemo(() => {
    return current_shopping_cart?.payment_methods?.some(
      (method) => method.enable && method.isPOS,
    );
  }, [current_shopping_cart?.payment_methods]);

  if (!current_shopping_cart?.payment_methods) return null;
  const { payment_methods } = current_shopping_cart;

  function handleAddPaymentMethod() {
    const currentPaymentMethods = [...payment_methods];
    if (nextMethodIndex !== -1) {
      const nextMethod = currentPaymentMethods[nextMethodIndex];
      const totalMoneyPaidByCustomer = sumBy(
        currentPaymentMethods.filter((method) => method.enable),
        (method) => method.amount,
      );

      currentPaymentMethods[nextMethodIndex] = {
        ...nextMethod,
        enable: true,
        amount:
          totalMoneyPaidByCustomer <
          current_shopping_cart.summaries.total_payment
            ? +Number(
                current_shopping_cart.summaries.total_payment -
                  totalMoneyPaidByCustomer,
              ).toFixed(4)
            : 0,
        index: payment_methods_active.length,
      };
      updateCurrentCart({ payment_methods: currentPaymentMethods });
    }
  }

  function handleRemovePaymentMethod(method) {
    updateCurrentCart({
      payment_methods: [...payment_methods].map((m) => ({
        ...m,
        enable: m.type === method.type ? false : m.enable,
        amount: m.type === method.type ? 0 : m.amount,
        index: m.enable && m.index > method.index ? m.index - 1 : m.index,
      })),
    });
  }

  const isEmptyCart = !current_shopping_cart?.lineItems?.length;

  return (
    <div className="payment-methods-wrapper" ref={refPaymentMethods}>
      {!isEmptyCart && payment_methods_active.length > 0 && (
        <React.Fragment>
          <div className="l-pt-8 l-pb-4">
            <p>Khách thanh toán</p>
          </div>
          <div className="">
            {payment_methods_active.map((method, i) => {
              return (
                <PaymentMethodItem
                  totalPaymentMethod={payment_methods_active?.length}
                  key={i}
                  method={method}
                  handleRemovePaymentMethod={() =>
                    handleRemovePaymentMethod(method)
                  }
                  width={refPaymentMethods.current?.offsetWidth}
                />
              );
            })}
          </div>
        </React.Fragment>
      )}
      {!isEmptyCart &&
        !isCodActive &&
        nextMethodIndex !== -1 &&
        !hasQRPayment &&
        !hasPOSMethod && (
          <div className="l-pt-8">
            <span
              className="d-inline-flex align-items-center cursor-pointer content-accent"
              onClick={() => handleAddPaymentMethod()}
            >
              <Icon
                name="add"
                color="var(--contentAccent)"
                className="l-mr-4"
              />
              <span>Thêm phương thức</span>
            </span>
          </div>
        )}
      <hr className="border-dotted my-0 l-mt-8 l-mb-8" />
      <div className="l-pb-4 d-flex justify-content-between font-weight-semibold">
        <p>Tiền khách đưa</p>
        <p className="font-size-16">
          {toFormattedCurrency(totalMoneyPaidByCustomer)}
        </p>
      </div>
      <div className="l-pt-4 l-pb-4 d-flex justify-content-between font-weight-semibold">
        <p>
          {totalMoneyPaidByCustomer >=
          current_shopping_cart?.summaries.total_payment
            ? "Tiền thừa trả khách"
            : !current_shopping_cart.customer
              ? "Tiền khách cần trả thêm"
              : "Tính vào công nợ"}{" "}
        </p>
        <p className="font-size-16">
          {toFormattedCurrency(
            Math.abs(
              Math.round(
                current_shopping_cart?.summaries.total_payment -
                  totalMoneyPaidByCustomer,
              ),
            ),
          )}
        </p>
      </div>
    </div>
  );
};
