import React, { useEffect, useState } from "react";
import PaymentSettingItem from "../PaymentSettingItem";
import { useIntl } from "react-intl";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import {
  disablePaymentMethod,
  enablePaymentMethod,
  getLocationPaymentDevices,
  addLocationPaymentDevice,
  updateLocationPaymentDevice,
  deleteLocationPaymentDevice,
} from "api/settings-repository";
import { useLayoutContext } from "contexts/Layout/layout-context";
import Button from "component-v2/buttons";
import { useApp } from "contexts/app";
import { CustomPaymentMethod } from "../CustomPaymentMethodModal";
import { Switch } from "@haravan/react-components";
import _ from "lodash";
import * as HrvComponents from "@haravan/react-components";
import Icon from "component-v2/icons/icons";
import { SelectionCustom } from "screens-v2/carts-v2/RightCart/components/SelectionCustom";
import { PayMEMethodCode } from "models/payment-methods-model";
import { LocationType } from "models/constants";

const EditGatewayModal = ({ show, setShow }) => {
  const appContext = useApp();
  const layout = useLayoutContext();
  const intl = useIntl();
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [currentMethod, setCurrentMethod] = useState(null);
  const [nextStepBody, setNextStepBody] = useState(null);
  const [showNextStep, setShowNextStep] = useState(false);
  const [modalHeaderTitle, setModalHeaderTitle] = useState(
    intl.formatMessage({
      id: "settings.generals.Thêm phương thức thanh toán",
      defaultMessage: "Thêm phương thức thanh toán",
    }),
  );
  const [methods, setMethods] = useState(
    appContext.shop_setting.paymentMethods,
  );
  const paymeMethod = appContext.shop_setting.paymentMethods?.find(
    (method) => method.code === PayMEMethodCode,
  );
  useEffect(() => {
    if (!show) {
      setShowNextStep(false);
    }
  }, [show]);

  const handleCreateCustomGateway = async (updateData) => {
    setMethods(updateData);
    appContext.updateShopSetting({ paymentMethods: updateData });
  };

  const renderCustomMethodList = () => {
    const customPaymentList = methods.filter(
      (method) => method.isCustom === true,
    );
    if (!customPaymentList) return null;

    return customPaymentList.map((method, index) => {
      return (
        <div
          key={method.name}
          className="d-flex flex-row justify-content-between my-3"
        >
          <div className="d-flex flex-row" style={{ flexBasis: "65%" }}>
            <div
              className={`d-flex justify-content-center mr-3 background-tertiary rounded-circle p-1`}
              style={{ width: "40px", height: "40px" }}
            >
              <HrvComponents.Avatar
                arrColor="gray"
                className="text-primary"
                shape="circle"
                content={method.name}
              ></HrvComponents.Avatar>
            </div>
            <span
              className={"align-self-center text-break"}
              style={{ flexBasis: "85%" }}
            >
              {method.name}
            </span>
          </div>
          <div className="text-right" style={{ flexBasis: "30%" }}>
            <Button
              onClick={() => {
                setCurrentMethod(method.code);
                setShowCustomModal(true);
              }}
              light
              children={<span>Chỉnh sửa</span>}
            />
            <Switch
              className="ml-3"
              checked={method.isEnable}
              onChange={() => handleUpdateGateway(method)}
            />
          </div>
        </div>
      );
    });
  };

  const handleUpdateGateway = async (method) => {
    const model = {
      code: method.code,
      locationId: appContext.current_location?.id,
    };
    let response = null;
    let updateData = _.cloneDeep(methods);
    const index = _.findIndex(updateData, { code: model.code });

    if (method.isEnable) {
      updateData[index].isEnable = false;
      response = await disablePaymentMethod(appContext, model);
    } else {
      updateData[index].isEnable = true;
      response = await enablePaymentMethod(appContext, model);
    }
    if (response?.data) {
      setMethods(updateData);
      appContext.updateShopSetting({ paymentMethods: updateData });
      layout.showGlobalToast(
        "default",
        "Cấu hình đã được cập nhật thành công!",
      );
    } else {
      layout.showGlobalToast(
        "error",
        "Cấu hình cập nhật không thành công. Vui lòng thử lại!",
      );
    }
  };

  const handleDeletePaymentMethod = async (updateData) => {
    if (updateData) {
      setMethods(updateData);
      setShowCustomModal(false);
      appContext.updateShopSetting({ paymentMethods: updateData });
    }
  };
  const onClickNextStepPOS = () => {
    setShowNextStep(true);
    setModalHeaderTitle(
      intl.formatMessage({
        id: "settings.generals.Máy POS quẹt thẻ",
        defaultMessage: "Máy POS quẹt thẻ",
      }),
    );
    setNextStepBody(<POSDeviceList appContext={appContext} />);
  };
  const body = () => {
    return (
      <>
        {/* momoqr */}
        <div className="border-bottom mb-4">
          <p className="font-weight-medium">Phương thức thanh toán QR</p>
          {methods
            ?.filter((method) => method.isQr && method.isCustom === false)
            ?.map((method) => {
              method.isEnable = !method.paymentInstructionId
                ? false
                : method.isEnable;
              return (
                <PaymentSettingItem
                  disabled={!method.paymentInstructionId}
                  key={method.name}
                  method={method}
                  onUpdateSetting={handleUpdateGateway}
                  additionalInfo={
                    !method.paymentInstructionId && (
                      <span className="content-warning l-mr-8 align-middle">
                        Phương thức này chưa được thiết lập
                      </span>
                    )
                  }
                />
              );
            })}
          <div className="app-container padding-bottom-12 padding-top-12 cursor-pointer">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div
                className="d-flex"
                onClick={() =>
                  window.open(
                    `${appContext.shop_info.seller_domain}/settings/payments`,
                    "_blank",
                    "noopener,noreferrer",
                  )
                }
              >
                <div
                  className={`d-flex justify-content-center mr-3 background-tertiary rounded-circle p-1`}
                  style={{ width: "40px", height: "40px" }}
                >
                  <Icon
                    name={"settings"}
                    color={"var(--contentSecondary)"}
                    className={"d-flex align-self-center"}
                  />
                </div>
                <p className="align-self-center text-decoration-none m-0">
                  Thiết lập cổng thanh toán tại MyHaravan
                </p>
              </div>
            </div>
          </div>
        </div>
        {paymeMethod && (
          <div className="border-bottom mb-4">
            <p className="font-weight-medium">
              Phương thức thanh toán máy POS quẹt thẻ
            </p>
            <PaymentSettingItem
              key={paymeMethod.name}
              method={paymeMethod}
              onUpdateSetting={handleUpdateGateway}
              additionalInfo={
                <span className="content-warning l-mr-8 align-middle">
                  <Button
                    light
                    title="Quản lý thiết bị"
                    onClick={() => onClickNextStepPOS()}
                  ></Button>
                </span>
              }
            />
          </div>
        )}
        <div className="border-bottom">
          <p className="font-weight-medium">Phương thức mặc định</p>
          {methods
            .filter(
              (method) =>
                !method.isQr &&
                method.isCustom === false &&
                method.code !== PayMEMethodCode,
            )
            .map((method) => (
              <PaymentSettingItem
                key={method.name}
                method={method}
                onUpdateSetting={handleUpdateGateway}
              />
            ))}
        </div>
        <div>
          <p className="font-weight-medium mt-4">
            Phương thức thanh toán tùy chỉnh
          </p>
          {/* <span className='text-secondary'>Đơn hàng sẽ được đánh dấu là "đã thanh toán" nếu chọn thanh toán bằng các phương thức này. Haravan POS sẽ không xử lý cá thanh toán tùy chỉnh.</span> */}
          {renderCustomMethodList()}
          <div className="d-flex flex-row">
            {/* Button Thêm  (Modal Add new, Edit gateway*/}
            <CustomPaymentMethod
              handleDeletePaymentMethod={(updateData) =>
                handleDeletePaymentMethod(updateData)
              }
              handleCreateCustomGateway={(updateData) =>
                handleCreateCustomGateway(updateData)
              }
              setCurrentMethod={setCurrentMethod}
              currentMethod={currentMethod}
              showCustomModal={showCustomModal}
              setShowCustomModal={setShowCustomModal}
              paymentMethods={methods}
              customPaymentList={methods.filter(
                (method) => method.isCustom === true,
              )}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <GlobalModal
      className={`${show && !showCustomModal ? "" : "d-none"}`}
      headerTitle={modalHeaderTitle}
      body={!showNextStep ? body() : nextStepBody}
      show={show}
      setShow={setShow}
      onCancel={() => setShow(false)}
      cancelTitle="Đóng"
      headerBack={showNextStep}
      onClickBack={() => {
        setShowNextStep(false);
        setModalHeaderTitle(
          intl.formatMessage({
            id: "settings.generals.Thêm phương thức thanh toán",
            defaultMessage: "Thêm phương thức thanh toán",
          }),
        );
      }}
    ></GlobalModal>
  );
};
const DeviceItem = ({
  newDevice,
  defaultValue,
  onBlur,
  onSubmit,
  onClickRemove,
  onClickClose,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [editMode, setEditMode] = useState(false);
  const onBlurInput = () => {
    onBlur?.();
  };
  const handleSubmitDevice = (e) => {
    if (!inputValue) return;
    onSubmit?.(inputValue, newDevice);
    setEditMode(false);
    e?.preventDefault();
  };
  const handleCloseInput = () => {
    setEditMode(false);
    onClickClose?.();
  };
  return (
    <div className="d-flex l-mb-8">
      <div
        className={`d-flex justify-content-center align-items-center l-mr-8 background-tertiary rounded-circle p-1`}
        style={{ minWidth: "40px", height: "40px" }}
      >
        <Icon name={"payment_device"} />
      </div>
      {newDevice || editMode ? (
        <form
          id="device-name-form"
          className="position-relative w-100"
          onSubmit={(e) => handleSubmitDevice(e)}
        >
          <HrvComponents.Input
            maxLength={20}
            placeholder={"Nhập tên máy"}
            id={"device-name-input"}
            value={inputValue}
            onChange={(value) => {
              setInputValue(value);
            }}
            onBlur={onBlurInput}
            autoComplete="off"
            autoFocus
          />
          <span
            onClick={() => handleSubmitDevice()}
            className="absolute-badge cursor-pointer background-tertiary"
            style={{ right: "40px", padding: "2px 6px" }}
          >
            Xong ↵
          </span>
          <span
            onClick={handleCloseInput}
            className="absolute-badge cursor-pointer background-tertiary"
            style={{ right: "8px", padding: "2px 4px" }}
          >
            <Icon name="close" size="xs" />
          </span>
        </form>
      ) : (
        <div className="d-flex justify-content-between align-items-center w-100">
          <span>{defaultValue}</span>
          <span className="d-flex gap-16">
            <span className="cursor-pointer" onClick={() => setEditMode(true)}>
              <Icon name={"edit_pencil"} />
            </span>
            <span className="cursor-pointer" onClick={onClickRemove}>
              <Icon name={"delete"} />
            </span>
          </span>
        </div>
      )}
    </div>
  );
};
const POSDeviceList = ({ appContext }) => {
  const shop_locations = appContext.shop_locations;
  const { showGlobalToast } = useLayoutContext();
  const intl = useIntl();
  const [selectedLocation, setSelectedLocation] = useState(shop_locations?.[0]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [devicesList, setDevicesList] = useState([]);
  const [showAddNew, setShowAddNew] = useState(false);
  const [showRemoveDeviceModal, setShowRemoveDeviceModal] = useState(false);
  useEffect(() => {
    if (selectedLocation?.id) {
      getLocationPaymentDevices(appContext.callAPI, selectedLocation.id).then(
        (res) => {
          if (!res?.errors && res?.data) {
            setDevicesList(res?.data);
          }
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation?.id]);

  const handleAddNewDevice = (deviceName) => {
    addLocationPaymentDevice(appContext.callAPI, {
      locationId: selectedLocation?.id,
      deviceName,
    }).then((res) => {
      if (res?.data && !res?.errors) {
        setShowAddNew(false);
        getLocationPaymentDevices(
          appContext.callAPI,
          selectedLocation?.id,
        ).then((res) => {
          if (!res?.errors && res?.data) {
            setDevicesList(res?.data);
            if (appContext?.current_location?.id === selectedLocation?.id) {
              appContext.setLocationDevices(res?.data);
            }
          }
          if (res?.errors) {
            const errorMsg =
              res?.errors?.[0] || "Đã có lỗi xảy ra, xin vui lòng thử lại.";
            showGlobalToast(
              "error",
              intl.formatMessage({
                id: `notifications.${errorMsg}`,
                defaultMessage: errorMsg,
              }),
            );
          }
        });
      }
    });
  };
  const handleUpdateDevice = (deviceName, deviceId) => {
    updateLocationPaymentDevice(appContext.callAPI, deviceId, {
      locationId: selectedLocation?.id,
      deviceName,
    }).then((res) => {
      if (res?.data && !res?.errors) {
        setShowAddNew(false);
        getLocationPaymentDevices(
          appContext.callAPI,
          selectedLocation?.id,
        ).then((res) => {
          if (!res?.errors && res?.data) {
            setDevicesList(res?.data);
            if (appContext?.current_location?.id === selectedLocation?.id) {
              appContext.setLocationDevices(res?.data);
            }
          }
          if (res?.errors) {
            const errorMsg =
              res?.errors?.[0] || "Đã có lỗi xảy ra, xin vui lòng thử lại.";
            showGlobalToast(
              "error",
              intl.formatMessage({
                id: `notifications.${errorMsg}`,
                defaultMessage: errorMsg,
              }),
            );
          }
        });
      }
    });
  };
  const handleRemoveDevice = (deviceId) => {
    deleteLocationPaymentDevice(appContext.callAPI, deviceId).then((res) => {
      if (res?.data && !res?.errors) {
        getLocationPaymentDevices(
          appContext.callAPI,
          selectedLocation?.id,
        ).then((res) => {
          if (!res?.errors && res?.data) {
            setDevicesList(res?.data);
            if (appContext?.current_location?.id === selectedLocation?.id) {
              appContext.setLocationDevices(res?.data);
            }
          }
          if (res?.errors) {
            const errorMsg =
              res?.errors?.[0] || "Đã có lỗi xảy ra, xin vui lòng thử lại.";
            showGlobalToast(
              "error",
              intl.formatMessage({
                id: `notifications.${errorMsg}`,
                defaultMessage: errorMsg,
              }),
            );
          }
        });
      }
    });
  };
  return (
    <>
      <SelectionCustom
        value={selectedLocation?.id}
        options={shop_locations}
        idField="id"
        valueField="name"
        onChange={(value) => {
          if (value !== selectedLocation?.id) {
            const location = shop_locations?.find((loc) => loc.id === value);
            setSelectedLocation(location);
          }
        }}
        style={{ width: "100%" }}
        prefix={"Chi nhánh: "}
      >
        {shop_locations
          ?.filter(
            (location) =>
              !location.isUnavailableQty &&
              !(location.typeId === LocationType.ON_THE_ROAD),
          )
          ?.map((u) => {
            return (
              <SelectionCustom.Option key={u.id} value={u.id}>
                <div className="d-flex align-items-center">{u.name}</div>
              </SelectionCustom.Option>
            );
          })}
      </SelectionCustom>
      <>
        <div
          className="d-flex l-mt-8 l-mb-8 cursor-pointer"
          onClick={() => setShowAddNew((prev) => !prev)}
        >
          <div
            className={`d-flex justify-content-center mr-3 background-tertiary rounded-circle p-1`}
            style={{ width: "40px", height: "40px" }}
          >
            <Icon
              name={"add"}
              color={"var(--contentSecondary)"}
              className={"d-flex align-self-center"}
            />
          </div>
          <p className="align-self-center text-decoration-none m-0">
            Thêm thiết bị
          </p>
        </div>
        {showAddNew && (
          <DeviceItem
            newDevice={true}
            onSubmit={(name) => handleAddNewDevice(name)}
            onClickClose={() => setShowAddNew(false)}
          />
        )}
        {devicesList?.map((device) => {
          return (
            <DeviceItem
              key={device.id}
              defaultValue={device.deviceName}
              onSubmit={(name) => handleUpdateDevice(name, device.id)}
              onClickRemove={() => {
                setSelectedDevice(device);
                setShowRemoveDeviceModal(true);
              }}
            />
          );
        })}
      </>
      <GlobalModal
        headerTitle={`Xóa máy ${selectedDevice?.deviceName}`}
        isTitleCenter
        body={
          <>
            <p className="mb-0">{`Hành động này không thể hoàn tác`}</p>
            <p className="mb-0">
              {`Bạn có chắc muốn xoá máy POS quẹt thẻ ${selectedDevice?.deviceName} khỏi chi nhánh ${selectedLocation.name}?`}
            </p>
          </>
        }
        show={showRemoveDeviceModal}
        setShow={setShowRemoveDeviceModal}
        onCancel={() => setShowRemoveDeviceModal(false)}
        onConfirm={() => {
          handleRemoveDevice(selectedDevice?.id);
          setShowRemoveDeviceModal(false);
        }}
        footer={
          <div>
            <Button
              light
              onClick={() => {
                setShowRemoveDeviceModal(false);
              }}
              title="Hủy"
            />
            <Button
              onClick={() => {
                handleRemoveDevice(selectedDevice?.id);
                setShowRemoveDeviceModal(false);
              }}
              status="danger"
              className="ml-3"
              title="Xóa"
            />
          </div>
        }
      />
    </>
  );
};
export default EditGatewayModal;
