import { createContext, useContext, useEffect, useState } from "react";
import { CacheStorage } from "infrastructures/storage-helpers";
import { initMoment, initNumeral } from "infrastructures/format-helpers";
import {
  callDelete,
  callGet,
  callPost,
  callPut,
  callUploadFile,
  callUploadFileMulti,
} from "api/api-connect-base";
import { ShopService } from "services";
import { cloneDeep } from "lodash";
import { diffFrom } from "utils/Commons";
import {
  BusinessProductCodeEnum,
  ReferrenceBusinessProductCodes,
  ShopPlanSupportedFunctions,
} from "utils/FeaturesUtils";
import AppService from "services/app-service";
import useSWR from "swr";
import { Fea_CartAction } from "configs";
import { getLocationPaymentDevices } from "api/settings-repository";
const Context = createContext({});
export const LOCALE_CACHE_KEY = "rtl-locale-lang";
export const LOCALE_CACHE_KEY_VI = "vi";
export const LOCALE_CACHE_KEY_EN = "en";
export const PINNED_PRICING_CACHE_KEY = "@haravan-pos:pinned_pricing_list";
export const LAST_USED_POS_DEVICE = "@haravan-pos:last_used_pos_device";

function AppProvider(props) {
  const { data } = useSWR("/auth/user");
  const [authData, setAuthData] = useState({
    authenticated: false,
    user: null,
  });
  const [currentProductCode, setCurrentProductCode] = useState(null);
  const [omniFeature, setOmniFeature] = useState(null);

  // thông tin cơ bản của shop
  const [shop_info, setShopInfo] = useState(null);

  // chứa setting retail của shop
  // nếu ready: false => shop chưa được setting lần đầu => liên hệ chủ shop login
  const [shop_setting, setShopSetting] = useState(null);

  const [isShopExpired, setIsShopExpired] = useState(false);

  // danh sách địa chỉ
  const [shop_locations, setShopLocations] = useState(null);

  // địa chỉ đang sử dụng để bán hàng/check tồn kho/...
  const [current_location, setCurrentLocation] = useState(null);

  // thiết lập của chi nhánh
  const [location_setting, setLocationSetting] = useState(null);

  // thông tin loyalty của shop
  const [shop_loyalty, setShopLoyalty] = useState(null);

  // true khi app đã load xong shop_info, shop_setting,shop_loyalty, shop_locations
  const [app_loaded, setAppLoaded] = useState(false);

  // true khi shop_info, shop_setting,shop_loyalty, shop_locations đều có đủ data (không lỗi)
  const [app_ready, setAppReady] = useState(false);

  // thay đổi khi current_location thay đổi
  const [staff_users, setStaffUsers] = useState(null);

  // Bảng giá của shop theo current_location
  const [pricingList, setPricingList] = useState([]);

  // ngôn ngữ/timezone
  const [locale, setLocale] = useState("");

  // API trả 401 cần đăng nhập lại
  const [login_required, setRequireLogin] = useState(false);

  // Setting shop chưa sẵn sàng
  const [setting_required, setRequireSetting] = useState(false);

  // toast message các lỗi API
  const [apiError, setApiError] = useState(null);

  // danh sách apps
  const [apps, setApps] = useState([]);

  // danh sách thiết bị payme của chi nhánh
  const [location_devices, setLocationDevices] = useState([]);

  useEffect(() => {
    if (!data) return;
    if (data?.user?.id === 0) {
      window.location.href = `/auth/login?returnUri=${window.location.href}`;
      return;
    }
    // isAuthenticated
    const _user = data?.user;
    const isAdmin =
      _user?.roles?.includes("admin") ||
      _user?.roles?.includes("com_api.admin");
    _user.is_admin = isAdmin;
    _user.orgid = _user?.orgId?.toString();
    _user.default = true;
    setAuthData({
      authenticated: true,
      user: _user,
    });

    let locale = "vi"; // CacheStorage.Get(LOCALE_CACHE_KEY, 'local')
    if (locale) initByLocale(locale);
    else switchLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.user?.id]);

  useEffect(() => {
    const afterAuthenticated = async () => {
      if (authData.authenticated) {
        const { user } = authData;
        let _shop_info = null;
        let _shop_setting = null;
        let _shop_locations = [];
        let _location_setting = null;
        let _current_location = null;
        let _apps = null;
        let _pricingList = null;
        _shop_info = await ShopService.getShopInfo(callAPI);
        // nếu không valid thử active
        if (_shop_info && !_shop_info?.valid_shop && user.is_admin) {
          const is_active = await ShopService.tryActiveShop(callAPI);
          _shop_info.valid_shop = is_active ? true : false;
        }
        const feature = getCurrentFeature(_shop_info?.org_features);
        const isExpired = checkExpired(feature);
        const hasNoRoles = !user?.roles || user?.roles.length === 0;
        setIsShopExpired(isExpired);
        setShopInfo(_shop_info);
        initFeatures(_shop_info?.org_features);
        if (isExpired || hasNoRoles) {
          setAppLoaded(true);
          setAppReady(true);
          return;
        }
        if (_shop_info?.valid_shop && _shop_info.valid_user) {
          _shop_setting = await ShopService.getShopSetting(
            callAPI,
            user.is_admin,
          );
          let { locations, current } = await ShopService.getShopLocations(
            callAPI,
            _shop_info,
            user,
          );
          if (current?.id) {
            _pricingList = await getPricingList(current.id);
          }
          _current_location = current;
          _shop_locations = locations;
          // get setting by location
          _location_setting = await ShopService.getShopSettingLocationDetail(
            callAPI,
            _current_location?.id,
          );
          // get loyalty setting
          getLoyaltySetting();
        }
        setShopSetting(_shop_setting);
        if (_shop_setting?.ready === false) setRequireSetting(true);
        setShopLocations(_shop_locations);
        setCurrentLocation(_current_location);
        if (_current_location) {
          const _location_devices = await getLocationPaymentDevices(
            callAPI,
            _current_location.id,
          );
          setLocationDevices(_location_devices?.data);
        }
        if (_pricingList?.length) {
          setPricingList(_pricingList);
        }
        setLocationSetting(_location_setting?.data);
        const _app_ready = user && _shop_info && _shop_setting ? true : false;
        setAppReady(_app_ready);
        setAppLoaded(true);
        if (Fea_CartAction(authData.user?.orgid)) {
          _apps = await AppService.getApps(callAPI);
          setApps(_apps);
        }
      }
    };
    afterAuthenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData.authenticated]);

  useEffect(() => {
    if (current_location) {
      const func = async () => {
        let staffs = await ShopService.getStaffUsers(
          callAPI,
          current_location.id,
          authData.user,
        );
        if (staffs) setStaffUsers(staffs);

        // Get default pricing list by current location
        if (pricingList?.length >= 0) {
          const pricingList = await getPricingList(current_location.id);
          setPricingList(pricingList);
        }

        // Get setting by location
        if (shop_setting.allowFilterVendorByLocation) {
          const _location_setting =
            await ShopService.getShopSettingLocationDetail(
              callAPI,
              current_location.id,
            );
          if (!!_location_setting?.data) {
            setLocationSetting(_location_setting?.data);
          }
        }
        const _location_devices = await getLocationPaymentDevices(
          callAPI,
          current_location.id,
        );
        setLocationDevices(_location_devices?.data);
      };
      func();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_location]);

  const getPricingList = async (locationId) => {
    const pricing = await ShopService.getPricingList(callAPI, locationId);
    let newPricingList = [{ id: null, name: "Bảng giá mặc định" }, ...pricing];
    return newPricingList;
  };

  const getLoyaltySetting = async () => {
    const _shop_loyalty = await ShopService.getLoyaltySetting(callAPI);
    setShopLoyalty(_shop_loyalty);
  };

  const getCurrentFeature = (features) => {
    let _features = features?.features ? features.features : [];
    if (!_features?.length) return null;
    let _currentFeature = null;
    var BreakException = {};
    try {
      _features.forEach((feature) => {
        let pattern = new RegExp(`(,|^)${feature.product_code}(,|$)`);
        let existFutures = ReferrenceBusinessProductCodes.find((prod) =>
          pattern.test(prod.refCodes, feature.product_code),
        );
        if (existFutures) {
          _currentFeature = feature;
          throw BreakException;
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
    return _currentFeature;
  };

  function initFeatures(features) {
    const feature = getCurrentFeature(features);
    if (feature) {
      setOmniFeature(feature);
      setCurrentProductCode(feature.product_code);
    }
  }

  const checkExpired = (feature) => {
    feature = feature || omniFeature;
    let remain = () => {
      if (feature?.expire_date) {
        return diffFrom(feature.expire_date);
      }
      return 0;
    };
    return remain() < 0;
  };

  const isOwner = () => {
    if (!shop_info.user_email || !shop_info.owner_email) return false;
    return (
      shop_info.user_email.toLowerCase() === shop_info.owner_email.toLowerCase()
    );
  };

  const checkShopPlanSupported = (sPFunction) => {
    if (!currentProductCode) return false;
    // kiểm tra shop plan có hỗ trợ ko
    let shop_plan_function = ShopPlanSupportedFunctions.find(
      (s) => s.function === sPFunction,
    );
    if (!shop_plan_function) return false;
    let pattern = new RegExp(`(,|^)${currentProductCode}(,|$)`);

    let result = pattern.test(shop_plan_function.supported_shopplans);
    // kiểm tra có hỗ trợ shop hết hạn ko
    if (result && checkExpired()) {
      pattern = new RegExp(`(,|^)${BusinessProductCodeEnum.__EXPIRED}(,|$)`);
      result = pattern.test(shop_plan_function.supported_shopplans);
    }
    return result;
  };

  const initByLocale = (locale) => {
    initMoment(locale);
    initNumeral(locale, "VND");
    setLocale(locale);
    // setTimezone
  };
  const switchLanguage = () => {
    let _language = "";
    switch (locale) {
      case "en":
        _language = LOCALE_CACHE_KEY_VI;
        break;
      case "vi":
        _language = LOCALE_CACHE_KEY_EN;
        break;
      default:
        _language = LOCALE_CACHE_KEY_VI;
        break;
    }
    CacheStorage.Set(LOCALE_CACHE_KEY, _language, "local");
    initByLocale(_language);
  };
  const setLanguage = (language) => {
    CacheStorage.Set(LOCALE_CACHE_KEY, language, "local");
    initByLocale(language);
  };
  const checkRoleAllow = (role) => {
    if (authData.user && authData.user.roles?.length) {
      return (
        authData.user.roles.includes(role) ||
        authData.user.roles.includes("admin") ||
        authData.user.roles.includes("com_api.admin")
      );
    } else {
      return false;
    }
  };
  const refreshShopSetting = async () => {
    const _shop_setting_new = await ShopService.getShopSettingNew(
      callAPI,
      authData.user.is_admin,
    );
    if (_shop_setting_new) setShopSetting(_shop_setting_new);
  };

  const updateShopSetting = ({ ...args }) => {
    const newShopSetting = cloneDeep(shop_setting);
    setShopSetting({
      ...newShopSetting,
      ...args,
    });
  };

  const refreshShopLocations = async () => {
    let { locations, current } = await ShopService.getShopLocations(
      callAPI,
      shop_info,
      authData.user,
    );
    if (locations && current) {
      setShopLocations(locations);
      setCurrentLocation(current);
    }
  };
  const setShopCurrentLocation = (key) => {
    const exist = shop_locations?.find((_l) => _l.id === key);
    if (exist) {
      setCurrentLocation(exist);
      const locationKey = `@haravan-pos:location:${authData.user.id}:${authData.user.orgid}`;
      localStorage.setItem(locationKey, JSON.stringify(exist));
    } else {
      setApiError("Địa chỉ chọn không tồn tại");
    }
  };
  const checkNotAllowUseCouponCodeWithLoyaltyDiscount = () => {
    if (shop_loyalty?.can_use_with_others) return true;
    return shop_loyalty?.is_allow && !shop_loyalty?.can_use_with_others;
  };

  async function callAPI(method, endpoint, body = null, showError = true) {
    let response = null;
    switch (method) {
      case "get":
        response = await callGet(endpoint);
        break;
      case "post":
        response = await callPost(endpoint, body);
        break;
      case "put":
        response = await callPut(endpoint, body);
        break;
      case "delete":
        response = await callDelete(endpoint);
        break;
      case "upload_file":
        response = await callUploadFile(endpoint, body);
        break;
      case "upload_file_multi":
        response = await callUploadFileMulti(endpoint, body);
        break;
      default:
        break;
    }
    if (showError) {
      if (response.status === 401) {
        setRequireLogin(true);
      }
      if (response.status === 403) {
        setApiError("Bạn không có quyền");
      }
      if (response.status === 500 || response.status === 504) {
        setApiError("Đã có lỗi xảy ra. vui lòng thử lại");
      }
    }
    if (response.status === 200) {
      const result = await response.json();
      result.status = result?.status ?? response.status;
      return result;
    }

    if (response.status === 422) {
      return { status: response.status, data: await response?.json() };
    }
    return { status: response.status };
  }

  function checkStoreId(listStoreId) {
    return listStoreId.includes(+authData?.user?.orgid);
  }
  return (
    <Context.Provider
      value={{
        auth: authData,
        isOwner,
        checkRoleAllow,
        shop_info,
        app_loaded,
        app_ready,
        shop_setting,
        refreshShopSetting,
        updateShopSetting,
        shop_loyalty,
        checkNotAllowUseCouponCodeWithLoyaltyDiscount,
        shop_locations,
        refreshShopLocations,
        current_location,
        setCurrentLocation: setShopCurrentLocation,
        staff_users,
        locale: { language: locale, switchLanguage, setLanguage },
        callAPI,
        login_required,
        setRequireLogin,
        setting_required,
        apiError,
        setApiError,
        checkStoreId,
        checkShopPlanSupported,
        omniFeature,
        apps,
        defaultPricingList: pricingList,
        isShopExpired,
        location_setting,
        setLocationSetting,
        location_devices,
        setLocationDevices,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

const useApp = () => {
  const app = useContext(Context);
  return app;
};

export { AppProvider, useApp };
