export const getIncompleteCheckoutSessions = async (callAPI, params) => {
  return await callAPI(
    "get",
    `/call/com_api/checkouts_v2/incomplete_sessions?locationId=${params?.locationId}&createdUserId=${params?.createdUserId}`,
  );
};

export const getCheckoutSession = async (callAPI, sessionId) => {
  return await callAPI(
    "get",
    `/call/com_api/checkouts_v2/session/${sessionId}`,
  );
};

export const createCheckoutSession = async (callAPI, model) => {
  return await callAPI("post", `/call/com_api/checkouts_v2/session`, model);
};

export const updateCheckoutSession = async (callAPI, sessionId, model) => {
  return await callAPI(
    "put",
    `/call/com_api/checkouts_v2/session/${sessionId}`,
    model,
  );
};

export const submitCheckoutSession = async (callAPI, sessionId, model) => {
  return await callAPI(
    "post",
    `/call/retail_api/orders/sessions/${sessionId}`,
    model,
  );
};
export const getDiscountRecommendation = async (callAPI, sessionId) => {
  return await callAPI(
    "get",
    `/call/com_api/checkouts_v2/promotions/${sessionId}`,
  );
};
export const deleteCheckoutSession = async (callAPI, sessionId) => {
  return await callAPI(
    "delete",
    `/call/com_api/checkouts_v2/session/${sessionId}`,
    null,
    false,
  );
};

export const repaymentCheckout = async (callAPI, sessionId, model) => {
  return await callAPI(
    "post",
    `/call/retail_api/orders/sessions/${sessionId}/pay`,
    model,
  );
};
