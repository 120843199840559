import React, { useEffect, useState } from "react";
import { Input, Pagination, Switch } from "@haravan/react-components";
import { FormattedMessage } from "react-intl";
import { updateSettingLocation } from "api/settings-repository";
import { useMemo } from "react";
import { useLayoutContext } from "contexts/Layout/layout-context";
import {
  LayoutCard,
  LayoutCardSection,
  LayoutCardHeader,
} from "screens-v2/layouts/layouts/omni-layout-card";
import { useApp } from "contexts/app";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Button from "component-v2/buttons";
import {
  HOTKEYS,
  HOTKEY_EVENTS,
  HOTKEY_SCOPES,
  LocationType,
} from "models/constants";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { ShopService } from "services";
import Icon from "component-v2/icons/icons";
import "./style.css";
import { getVendorAPI } from "api/product-repository";
import CouponBadge from "screens-v2/carts-v2/RightCart/components/couponBadge";
import { convertViToEn } from "utils/Commons";
import Tooltip from "rc-tooltip";
import { concatString } from "screens-v2/carts-v2/utils";

const LOCATION_CODE_SUPPORT_URL =
  "https://support.haravan.com/support/solutions/articles/42000109308-c%E1%BA%A5u-h%C3%ACnh-qu%E1%BA%A3n-l%C3%BD-chi-nh%C3%A1nh#content1";
const VENDOR_FILTER_SUPPORT_URL =
  "https://support.haravan.com/support/solutions/articles/42000109308-c%E1%BA%A5u-h%C3%ACnh-qu%E1%BA%A3n-l%C3%BD-chi-nh%C3%A1nh#content2";

const LocationSetting = () => {
  const {
    callAPI,
    shop_setting,
    current_location,
    shop_locations,
    refreshShopSetting,
    setLocationSetting,
    location_setting,
  } = useApp();
  const layout = useLayoutContext();
  const validLocations = useMemo(() => {
    return shop_locations?.filter(
      (loc) =>
        !loc.isUnavailableQty && !(loc.typeId === LocationType.ON_THE_ROAD),
    );
  }, [shop_locations]);
  const [vendorSearchText, setVendorSearchText] = useState("");
  const [showVendorsModal, setShowVendorsModal] = useState(false);
  const [showLocationCodeModal, setShowLocationCodeModal] = useState(false);
  const [settingLocations, setSettingLocations] = useState([]);
  const [expandedRowData, setExpandedRowData] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [vendorsResult, setVendorsResult] = useState([]);
  const [showMoreVendors, setShowMoreVendors] = useState(false);
  const [newSelectedVendors, setNewSelectedVendors] = useState("");
  const [locationCodeInputValue, setLocationCodeInputValue] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 5,
  });

  useEffect(() => {
    if (!!validLocations?.length && pagination) {
      let locations = validLocations?.slice(
        (pagination.page - 1) * pagination.limit,
        pagination.page * pagination.limit,
      );
      const locationIds = locations?.map((loc) => loc?.id)?.join(",");
      ShopService.getShopSettingLocations(callAPI, locationIds)?.then((res) => {
        if (res && !!res?.data) {
          locations = locations?.map((loc) => {
            return {
              ...loc,
              locationAddress: concatString({
                value: [
                  loc.street,
                  loc.wardName,
                  loc.districtName,
                  loc.provinceName,
                  loc.countryName,
                ],
                separator: ", ",
              }),
              ...res?.data?.locationItems?.find(
                (item) => item.locationId === loc.id,
              ),
            };
          });
          setSettingLocations(locations);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validLocations, pagination]);

  useEffect(() => {
    getVendorAPI(callAPI)
      .then((res) => {
        if (!res?.errors && res?.data) {
          setVendors(res.data);
          setVendorsResult(res.data);
        }
      })
      .catch(() => {
        return null;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickVendor = (vendorName) => {
    const vendors = newSelectedVendors?.split(", ") || [];
    const index = vendors.indexOf(encodeURIComponent(vendorName));
    if (index > -1) {
      onRemoveVendor(encodeURIComponent(vendorName));
      return;
    }
    if (vendors?.length >= 5) return;
    setNewSelectedVendors(
      [...vendors, encodeURIComponent(vendorName)].filter(Boolean)?.join(", "),
    );
  };
  const onRemoveVendor = (vendorName) => {
    const vendors = newSelectedVendors?.split(", ") || [];
    const index = vendors.indexOf(vendorName);
    if (index > -1) {
      vendors.splice(index, 1);
    }
    setNewSelectedVendors(vendors.filter(Boolean).join(", "));
  };
  const onSearchVendor = (searchText) => {
    setVendorSearchText(searchText);
    if (!searchText) {
      setVendorsResult([...vendors]);
      return;
    }
    const searchRes = vendors?.filter((v) =>
      convertViToEn(v.name)?.includes(convertViToEn(searchText?.trim())),
    );
    setVendorsResult(searchRes);
  };
  useEffect(() => {
    if (!showVendorsModal) {
      setVendorSearchText("");
      setVendorsResult([...vendors]);
    }
  }, [showVendorsModal, vendors]);
  const confirmUpdateLocationVendors = () => {
    ShopService.updateShopSettingLocation(callAPI, {
      items: [
        {
          type: `vendor_${expandedRowData?.locationId}`,
          value: newSelectedVendors,
        },
      ],
    }).then((res) => {
      if (res && res?.data && !res?.errors) {
        const locations = [...settingLocations];
        const index = locations?.findIndex(
          (location) => location.locationId === expandedRowData?.locationId,
        );
        const newData = { ...expandedRowData, vendors: newSelectedVendors };
        locations[index] = newData;
        if (index <= -1) return;
        setExpandedRowData(newData);
        setSettingLocations(locations);
        setLocationSetting({
          ...location_setting,
          vendors: newSelectedVendors,
        });
        layout.showGlobalToast(
          "default",
          "Cấu hình đã được cập nhật thành công!",
        );
      }
    });
  };
  const validateLocationCode = (code) => {
    if (code) {
      return /^([A-Z0-9-]{0,12})$/.test(code);
    }
    return true;
  };
  const confirmUpdateLocationCode = () => {
    ShopService.updateShopSettingLocation(callAPI, {
      items: [
        {
          type: `prefix_${expandedRowData?.locationId}`,
          value: locationCodeInputValue,
        },
      ],
    }).then((res) => {
      if (res && res?.data && !res?.errors) {
        const locations = [...settingLocations];
        const index = locations?.findIndex(
          (location) => location.locationId === expandedRowData?.locationId,
        );
        const newData = {
          ...expandedRowData,
          locationCode: locationCodeInputValue,
        };
        setExpandedRowData(newData);
        locations[index] = newData;
        if (index <= -1) return;
        setSettingLocations(locations);
        layout.showGlobalToast(
          "default",
          "Cấu hình đã được cập nhật thành công!",
        );
      }
      if (!!res?.errors?.length) {
        layout.showGlobalToast("error", res?.errors[0]);
      }
    });
  };
  const locationValues = useMemo(() => {
    return {
      allowOrderNumberByLocation: shop_setting?.allowOrderNumberByLocation,
      allowFilterVendorByLocation: shop_setting?.allowFilterVendorByLocation,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop_setting]);
  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F8],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC) {
        showVendorsModal && setShowVendorsModal(false);
        showLocationCodeModal && setShowLocationCodeModal(false);
      }
      if (e.key === HOTKEY_EVENTS.F8) {
        if (showVendorsModal) {
          confirmUpdateLocationVendors();
          setShowVendorsModal(false);
        }

        if (showLocationCodeModal) {
          confirmUpdateLocationCode();
          setShowLocationCodeModal(false);
        }
      }
    },
    enabled: showVendorsModal || showLocationCodeModal,
    scopes: HOTKEY_SCOPES.MODAL,
  });
  const onUpdateSetting = async (settingKey) => {
    const value = !shop_setting[settingKey];
    let model = {
      ...locationValues,
      [settingKey]: value,
    };
    const response = await updateSettingLocation(
      callAPI,
      model,
      current_location.id,
    );
    if (response.data) {
      await refreshShopSetting();
      layout.showGlobalToast(
        "default",
        "Cấu hình đã được cập nhật thành công!",
      );
    } else {
      layout.showGlobalToast(
        "error",
        "Cấu hình cập nhật không thành công. Vui lòng thử lại!",
      );
    }
  };

  const renderLocations = (data) => {
    const handleClickExpandRow = (row) => {
      if (expandedRowData?.locationId === row.locationId) {
        setExpandedRowData(null);
        return;
      }
      setExpandedRowData(row);
    };
    return data?.map((item, index) => {
      const isExpanded =
        expandedRowData && expandedRowData?.locationId === item.locationId;
      const expandedCellPadding = !isExpanded
        ? "l-p-16"
        : "l-pb-8 l-pr-16 l-pl-16 l-pt-16";
      return (
        <React.Fragment key={index}>
          <tr
            className={`cursor-pointer border-top ${
              isExpanded ? "border-bottom-0" : "border-bottom"
            }`}
            onClick={() => handleClickExpandRow(item)}
          >
            <td
              className={`${expandedCellPadding} align-middle text-left content-accent`}
            >
              {item.locationName || "---"}
            </td>
            <td className={`${expandedCellPadding} align-middle text-center`}>
              {item.locationCode || "---"}
            </td>
            <td className={`${expandedCellPadding} align-middle text-left`}>
              {item.locationAddress || "---"}
            </td>
            <td className={`${expandedCellPadding} align-middle border-0`}>
              <div className="w-100 d-flex vendors-badge-wrapper align-items-center justify-content-center">
                <span className="vendors-badge text-center">
                  {!item.vendors ? "Tất cả" : decodeURIComponent(item.vendors)}
                </span>
              </div>
            </td>
            <td
              className={`${expandedCellPadding} align-middle text-right cursor-pointer`}
            >
              <div className="col-1 d-flex align-items-center expand-icon">
                <Icon
                  className={isExpanded ? "rotate-90" : ""}
                  size="2.4"
                  name={"chevron_right"}
                />
              </div>
            </td>
          </tr>
          {isExpanded && (
            <>
              <tr className="border-none row-expandable">
                <td className="p-0 l-pb-8 l-pl-16 l-pr-16 align-middle text-left content-secondary font-size-12">
                  <FormattedMessage
                    id="settings.location.Lọc nhà cung cấp"
                    defaultMessage="Lọc Nhà Cung Cấp"
                  />
                  <Tooltip
                    overlay={
                      <FormattedMessage
                        id="settings.location.Giới hạn kết quả tìm kiếm chỉ chứa sản phẩm thuộc bộ lọc nhà cung cấp"
                        defaultMessage="Giới hạn kết quả tìm kiếm chỉ chứa sản phẩm thuộc bộ lọc nhà cung cấp"
                      />
                    }
                    placement="top"
                    key={"vendor-tooltip"}
                  >
                    <span>
                      <Icon
                        className={"content-secondary l-ml-4"}
                        size="1.8"
                        name={"help"}
                        color="var(--contentSecondary)"
                      />
                    </span>
                  </Tooltip>
                </td>
                <td
                  colSpan={4}
                  className="p-0 l-pb-8 l-pl-16 l-pr-16 align-middle text-left"
                >
                  <div className="d-inline-flex">
                    <span className="l-mr-8">
                      {!item.vendors
                        ? "Tất cả nhà cung cấp"
                        : decodeURIComponent(item.vendors)}
                    </span>
                    <div
                      onClick={() => {
                        setNewSelectedVendors(item?.vendors);
                        setShowVendorsModal(true);
                      }}
                      className={
                        "rounded border-0 edit-vendor-btn cursor-pointer"
                      }
                    >
                      <Icon
                        className={"btn-icon"}
                        size={"sm"}
                        name={"edit_pencil"}
                        color={"var(--contentAccent)"}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="border-none row-expandable">
                <td className="l-pt-8 l-pb-16 l-pl-16 l-pr-16 l-pr-16 align-middle text-left content-secondary font-size-12">
                  <FormattedMessage
                    id="settings.location.Mã Chi Nhánh"
                    defaultMessage="Mã Chi Nhánh"
                  />
                  <Tooltip
                    overlay={
                      <FormattedMessage
                        id="settings.location.Mã định danh cho chi nhánh"
                        defaultMessage="Mã định danh cho chi nhánh"
                      />
                    }
                    placement="top"
                    key={"location-code-tooltip"}
                  >
                    <span>
                      <Icon
                        className={"content-secondary l-ml-4"}
                        size="1.8"
                        name={"help"}
                        color="var(--contentSecondary)"
                      />
                    </span>
                  </Tooltip>
                </td>
                <td
                  colSpan={4}
                  className="l-pt-8 l-pb-16 l-pl-16 l-pr-16 align-middle text-left"
                >
                  <div className="d-inline-flex">
                    <span className="l-mr-4">{item.locationCode || "---"}</span>
                    <div
                      onClick={() => {
                        setLocationCodeInputValue(item?.locationCode || "");
                        setShowLocationCodeModal(true);
                      }}
                      className={
                        "rounded border-0 edit-vendor-btn cursor-pointer"
                      }
                    >
                      <Icon
                        className={"btn-icon"}
                        size={"sm"}
                        name={"edit_pencil"}
                        color={"var(--contentAccent)"}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </>
          )}
        </React.Fragment>
      );
    });
  };
  return (
    <LayoutCard className="location-setting-card bg-transparent">
      <LayoutCardSection className="pt-0">
        <LayoutCardHeader title="Quản lý chi nhánh" />
        <p className="text-secondary mt-2">Cấu hình nâng cao cho chi nhánh</p>
        <div className="border-top mt-4">
          <p className="mt-4 text-secondary font-weight-medium">
            <FormattedMessage
              id="settings.location.Cấu hình nâng cao"
              defaultMessage="Cấu hình nâng cao"
            />
          </p>
          <LayoutCard className="l-mt-8">
            <LayoutCardSection className="flex-row">
              <div className="flex-stretch">
                <p className="text-default font-weight-medium">
                  <FormattedMessage
                    id="settings.location.Mã đơn hàng theo chi nhánh"
                    defaultMessage="Mã đơn hàng theo chi nhánh"
                  />
                </p>
                <p className="text-secondary font-size-12">
                  <FormattedMessage
                    id="settings.location.Tính năng sẽ tách bộ đếm đơn hàng riêng cho mỗi chi nhánh. {a}"
                    defaultMessage="Tính năng sẽ tách bộ đếm đơn hàng riêng cho mỗi chi nhánh. {a}"
                    values={{
                      a: (
                        <a
                          className="text-decoration-none"
                          href={LOCATION_CODE_SUPPORT_URL}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Xem thêm.
                        </a>
                      ),
                    }}
                  />
                </p>
              </div>
              <div className="col-auto text-right">
                <Switch
                  onChange={() => onUpdateSetting("allowOrderNumberByLocation")}
                  checked={locationValues.allowOrderNumberByLocation}
                />
              </div>
            </LayoutCardSection>
            <LayoutCardSection className="flex-row">
              <div className="flex-stretch">
                <p className="text-default font-weight-medium">
                  <FormattedMessage
                    id="settings.location.Bộ lọc nhà cung cấp theo chi nhánh"
                    defaultMessage="Bộ lọc nhà cung cấp theo chi nhánh"
                  />
                </p>
                <p className="text-secondary font-size-12">
                  <FormattedMessage
                    id="settings.location.Giới hạn sản phẩm có thể tìm kiếm theo nhà cung cấp trên mỗi chi nhánh. {a}"
                    defaultMessage="Giới hạn sản phẩm có thể tìm kiếm theo nhà cung cấp trên mỗi chi nhánh. {a}"
                    values={{
                      a: (
                        <a
                          className="text-decoration-none"
                          href={VENDOR_FILTER_SUPPORT_URL}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Xem thêm.
                        </a>
                      ),
                    }}
                  />
                </p>
              </div>
              <div className="col-auto text-right">
                <Switch
                  onChange={() =>
                    onUpdateSetting("allowFilterVendorByLocation")
                  }
                  checked={locationValues.allowFilterVendorByLocation}
                />
              </div>
            </LayoutCardSection>
          </LayoutCard>
        </div>
        <div className="mt-4">
          <p className="mt-4 text-secondary font-weight-medium">
            <FormattedMessage
              id="settings.location.Thiết lập chi nhánh"
              defaultMessage="Thiết lập chi nhánh"
            />
          </p>
          <LayoutCard className="l-mt-8 overflow-hidden">
            <LayoutCardSection className="p-0">
              <div>
                <table className="table location-table border-bottom">
                  <thead className="location-table-header">
                    <tr>
                      <th className="l-p-16 font-weight-medium w-20 align-middle">
                        <FormattedMessage
                          id="settings.location.Chi nhánh"
                          defaultMessage="Chi nhánh"
                        />
                      </th>
                      <th className="l-p-16 font-weight-medium w-20 align-middle text-center">
                        <FormattedMessage
                          id="settings.location.Mã chi nhánh"
                          defaultMessage="Mã chi nhánh"
                        />
                      </th>
                      <th className="l-p-16 font-weight-medium w-40 align-middle">
                        <FormattedMessage
                          id="settings.location.Địa chỉ"
                          defaultMessage="Địa chỉ"
                        />
                      </th>
                      <th className="l-p-16 font-weight-medium w-20 align-middle text-center">
                        <FormattedMessage
                          id="settings.location.Nhà cung cấp"
                          defaultMessage="Nhà cung cấp"
                        />
                      </th>
                      <th className="l-p-16 font-weight-medium align-middle"></th>
                    </tr>
                  </thead>
                  <tbody>{renderLocations(settingLocations)}</tbody>
                </table>
                {/* Pagination */}
                <div className="row">
                  <div className="col-12">
                    <div className="product-search-list--pagination padding-top-16">
                      <div
                        className="search-pagination"
                        style={{ width: "100%" }}
                      >
                        <Pagination
                          className="l-ml-16 l-mr-16 l-mb-8"
                          showChangePageSize={false}
                          hideOnSinglePage
                          defaultPageSize={5}
                          total={validLocations?.length}
                          onChange={(page, limit) => {
                            setPagination({ page, limit });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </LayoutCardSection>
          </LayoutCard>
        </div>
      </LayoutCardSection>

      <GlobalModal
        headerTitle={`Lọc nhà cung cấp: ${`${expandedRowData?.locationName}`}`}
        isTitleCenter
        show={showVendorsModal}
        setShow={setShowVendorsModal}
        body={
          <>
            {!newSelectedVendors ? (
              <p className="margin-top-10 text-custom-color l-mb-16">
                <FormattedMessage
                  id="settings.location.Không lọc nhà cung cấp"
                  defaultMessage="Không lọc nhà cung cấp"
                />
              </p>
            ) : (
              <div className="d-inline-flex flex-wrap l-mb-16">
                {newSelectedVendors?.split(", ")?.map((vendor) => {
                  return (
                    <CouponBadge
                      key={vendor}
                      className={"l-pr-8 l-mr-8 mt-2 cursor-pointer"}
                      couponLabel={
                        <p className="m-0 l-pl-8">
                          {decodeURIComponent(vendor)}
                        </p>
                      }
                      canRemove={true}
                      iconBackground={"white"}
                      onClickRemove={() => onRemoveVendor(vendor)}
                    />
                  );
                })}
              </div>
            )}
            <div className="w-100 vendor-search-wrapper">
              <Input
                placeholder={"Tìm nhà cung cấp"}
                id={"vendors"}
                value={vendorSearchText}
                onChange={(value) => onSearchVendor(value)}
              />
              {vendorSearchText?.length > 0 && (
                <Button
                  size="sm"
                  plain
                  icon="close"
                  title=""
                  className={`btn-remove`}
                  onClick={() => {
                    setVendorSearchText("");
                    onSearchVendor("");
                  }}
                />
              )}
            </div>
            <div className={"d-inline-flex flex-wrap l-mt-8"}>
              {(vendorsResult?.length > 20 && !showMoreVendors
                ? vendorsResult?.slice(0, 21)
                : vendorsResult
              )?.map((vendor, index) => {
                return (
                  <CouponBadge
                    key={`shop-${vendor.id}-${index}`}
                    onClick={() => onClickVendor(vendor.name)}
                    className={`l-pr-8 l-mr-8 mt-2 cursor-pointer ${
                      decodeURIComponent(newSelectedVendors)
                        ?.split(", ")
                        ?.indexOf(vendor.name) > -1
                        ? "vendor-selected"
                        : ""
                    }`}
                    couponLabel={<p className="m-0 l-pl-8">{vendor.name}</p>}
                    canRemove={false}
                    iconBackground={"white"}
                  />
                );
              })}
              {vendorsResult?.length > 20 && (
                <Button
                  size="sm"
                  plain
                  status="primary"
                  title={`${showMoreVendors ? "Thu gọn" : "Xem thêm"}`}
                  className={`show-more-btn l-mr-8 mt-2 font-size-12`}
                  onClick={() => setShowMoreVendors((prev) => !prev)}
                />
              )}
            </div>
          </>
        }
        footer={
          <>
            <div className="mt-4 d-flex justify-content-end align-items-center">
              <Button
                className="mr-2 light text-uppercase"
                onClick={() => {
                  setShowVendorsModal(false);
                }}
                title="Hủy bỏ"
                badge={HOTKEYS.ESC}
              />
              <Button
                status="primary"
                onClick={() => {
                  setShowVendorsModal(false);
                  confirmUpdateLocationVendors();
                }}
                title="Lưu"
                badge={HOTKEYS.F8}
              />
            </div>
          </>
        }
      />
      <GlobalModal
        size="sm"
        headerTitle={`Mã chi nhánh: ${`${expandedRowData?.locationName}`}`}
        isTitleCenter
        show={showLocationCodeModal}
        setShow={setShowLocationCodeModal}
        body={
          <div className="d-flex gap-8 l-mt-8 l-mb-8">
            <span className="d-flex align-items-center text-custom-color text-middle align-middle w-25">
              <FormattedMessage
                id="settings.location.Mã chi nhánh"
                defaultMessage="Mã chi nhánh"
              />
            </span>
            <Input
              maxLength="12"
              className="text-right"
              placeholder={expandedRowData?.locationCode || "Mã chi nhánh"}
              id={"location-code"}
              value={locationCodeInputValue}
              onChange={(value) => setLocationCodeInputValue(value)}
              autoComplete="off"
            />
          </div>
        }
        footer={
          <>
            <div className="mt-4 d-flex justify-content-end align-items-center">
              <Button
                className="mr-2 light text-uppercase"
                onClick={() => {
                  setShowLocationCodeModal(false);
                }}
                title="Hủy bỏ"
                badge={HOTKEYS.ESC}
              />
              <Button
                status="primary"
                onClick={() => {
                  if (!validateLocationCode(locationCodeInputValue)) {
                    layout.showGlobalToast(
                      "error",
                      "Mã chi nhánh chỉ bao gồm chữ in hoa, chữ số, tối đa 12 ký tự",
                    );
                    return;
                  }
                  setShowLocationCodeModal(false);
                  confirmUpdateLocationCode();
                }}
                title="Lưu"
                badge={HOTKEYS.F8}
              />
            </div>
          </>
        }
      />
    </LayoutCard>
  );
};

export default LocationSetting;
