import React from "react";
import { Input } from "@haravan/react-components";
import Icon from "component-v2/icons/icons";
import { useOnClickOutside } from "hooks/useOnClickOutSide";
import { cloneElement, useEffect, useMemo, useRef, useState } from "react";
import { useWindowSize } from "hooks/useWindowSize";
import { convertViToEn, getZoomTarget } from "utils/Commons";
import "./selection.css";
import { getChromeVersion } from "infrastructures/utils";

export const SelectionCustom = ({
  width,
  onChange,
  options,
  idField,
  valueField,
  value,
  children,
  prefix,
  disabled,
  showSearch,
  onSearch,
  placeholder,
  hidden,
  style,
  onPopupScroll,
  notFoundContent,
  firstICon,
  inputId,
}) => {
  const [searchText, setSearchText] = useState("");
  const [isShowResult, setIsShowResult] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [selectionOptions, setSelectionOptions] = useState(options);
  const [selectedOption, setSelectedOption] = useState(null);
  const this_ref = useRef(null);
  const refInput = useRef(null);
  const refDropdown = useRef(null);
  const refDropUp = useRef(false);

  const { width: windowWidth } = useWindowSize();
  const zoomTarget = useMemo(() => getZoomTarget(windowWidth), [windowWidth]);
  const positionInputSelection = refInput?.current?.getBoundingClientRect();

  useOnClickOutside(this_ref, () => {
    setIsShowResult(false);
    showSearch && setIsSearchFocused(false);
  });
  const chromeVer = getChromeVersion();
  const newZoomStandard = !chromeVer
    ? zoomTarget
    : chromeVer >= 128
      ? zoomTarget
      : 1;
  function determineDropUp() {
    if (!this_ref.current) return;
    const isUp =
      window.innerHeight / zoomTarget -
        refInput.current?.getBoundingClientRect().bottom / newZoomStandard <
      refDropdown.current?.offsetHeight + 8;
    refDropUp.current = isUp;
  }
  useEffect(() => {
    window.addEventListener("resize", determineDropUp);
    window.addEventListener("scroll", determineDropUp);
    return () => {
      window.removeEventListener("resize", determineDropUp);
      window.removeEventListener("scroll", determineDropUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (refDropdown.current && isShowResult)
  //     determineDropUp()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isShowResult])

  useEffect(() => {
    if (isShowResult) {
      setSelectionOptions(options || []);
    }
    const exists = options?.find((o) => o[idField] === value);
    if (exists) {
      setSelectedOption(exists);
    } else {
      setSelectedOption(null);
    }
  }, [options, value, idField, isShowResult]);

  const handleSearchOptions = (keyword) => {
    let searchRes = selectionOptions;
    if (keyword) {
      searchRes = options?.filter((o) =>
        convertViToEn(o[valueField]?.toString())?.includes(
          convertViToEn(keyword),
        ),
      );
    }
    setSelectionOptions(searchRes);
  };
  const handleInputFocus = () => {
    if (showSearch) {
      setIsSearchFocused(true);
      setSearchText("");
    }
    setIsShowResult(true);
  };

  const handleInputChanged = async (value) => {
    setSearchText(value);
    onSearch ? onSearch(value) : handleSearchOptions(value);
  };

  const content = () => {
    return children?.map((c, index) => {
      const { value: optionValue } = c.props;
      if (
        searchText &&
        showSearch &&
        !onSearch &&
        !selectionOptions?.find((o) => o[idField] === optionValue)
      ) {
        return (
          <React.Fragment key={`${optionValue}-${index}`}></React.Fragment>
        );
      }
      const element = cloneElement(c, {
        onSelect: () => {
          setIsShowResult(false);
          showSearch && setIsSearchFocused(false);
          onChange(optionValue);
          setSelectedOption(
            selectionOptions?.find((o) => o[idField] === optionValue),
          );
        },
        key: `${optionValue}-${index}`,
        active: optionValue === value,
      });
      return element;
    });
  };

  const emptyData = () => {
    return (
      <>
        {notFoundContent ? (
          <div className="py-2">
            <p className="font-size-16 text-center content-secondary">
              {notFoundContent}
            </p>
          </div>
        ) : (
          <div className="py-2">
            <p className="font-size-16 text-center content-secondary">
              Không có dữ liệu
            </p>
          </div>
        )}
      </>
    );
  };

  const renderContent = () => {
    return (
      <div
        ref={(ref) => {
          refDropdown.current = ref;
          if (ref) determineDropUp();
        }}
        className="rt-selection-dropdown"
        style={{ width: width ? `${width}px` : "100%" }}
      >
        <div className="rt-selection-content">
          {(
            showSearch && !onSearch
              ? !!selectionOptions?.length
              : !!options?.length
          )
            ? content()
            : emptyData()}
        </div>
      </div>
    );
  };
  return (
    <>
      <div
        style={style ? style : {}}
        ref={this_ref}
        className={`position-relative cursor-pointer select-quick-product padding-content`}
        onClick={() => !disabled && handleInputFocus()}
      >
        <div
          className={`rt-input-selection-wrapper ${
            isShowResult ? "focused" : ""
          }`}
          ref={refInput}
          id={inputId}
        >
          {firstICon ? (
            <div className="flex-auto l-mr-4">
              <Icon name={firstICon} color="var(--contentTertiary)" />
            </div>
          ) : null}
          {prefix ? (
            <div
              className={`flex-auto l-mr-4 ${
                disabled ? "content-tertiary" : ""
              }`}
            >
              {prefix}
            </div>
          ) : null}
          {showSearch ? (
            <Input
              autoComplete="off"
              placeholder={selectedOption?.[valueField] ?? placeholder}
              value={
                isSearchFocused ? searchText : selectedOption?.[valueField]
              }
              disabled={disabled}
              onChange={(value) => handleInputChanged(value)}
              className="rt-input-selection-search__field w-100"
              onFocus={() => {
                setIsSearchFocused(true);
                onSearch ? onSearch("") : handleSearchOptions("");
                setSearchText("");
              }}
              onBlur={() => {
                setIsSearchFocused(false);
              }}
            />
          ) : (
            <div
              className={`flex-stretch select-value text-left text-truncate ${
                disabled ? "content-tertiary" : ""
              }`}
            >
              {selectedOption?.[valueField] ?? placeholder}
            </div>
          )}
          <div className="flex-auto l-ml-4">
            <Icon name="keyboard_arrow_down" color="var(--contentTertiary)" />
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            top: refDropUp.current
              ? positionInputSelection?.top / newZoomStandard -
                  refDropdown.current?.offsetHeight -
                  10 || 0
              : (positionInputSelection?.top + positionInputSelection?.height) /
                  newZoomStandard +
                  10 || 0,
            left: positionInputSelection?.left / newZoomStandard || 0,
            width: (positionInputSelection?.width || 0) / newZoomStandard,
            zIndex: 100,
            visibility: isShowResult ? "visible" : "hidden",
          }}
          onScroll={(e) => onPopupScroll?.(e)}
        >
          {renderContent()}
        </div>
      </div>
    </>
  );
};

const Option = ({ value, active, disabled, onSelect, children, hidden }) => {
  return (
    <div
      className={`rt-selection-item text-left ${active ? "active" : ""} ${
        disabled ? "disabled" : ""
      } ${hidden ? "d-none" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
        !disabled && onSelect?.();
      }}
    >
      {children}
    </div>
  );
};

SelectionCustom.Option = Option;
