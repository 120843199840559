import * as HrvComponents from "@haravan/react-components";
import Icon from "component-v2/icons/icons";
import { sumBy, toNumber } from "lodash";
import { useMemo } from "react";
import { InputNumber } from "../components/InputNumber";
import _ from "lodash";
import Logo from "component-v2/logos/logos";

import { useRefundContext } from "screens-v2/refunds-v2";
import { SelectionCustom } from "screens-v2/carts-v2/RightCart/components/SelectionCustom";
import { PaymentAuthCode } from "screens-v2/carts-v2/RightCart/Payments/paymentAuthCode";
import { useApp } from "contexts/app";

const AMOUNT_MAX = 50000000000;

export const PaymentMethodItem = ({
  method,
  handleRemovePaymentMethod,
  width,
}) => {
  const {
    current_shopping_cart,
    updateCurrentCart,
    finalPrice,
    current_refund,
    updateCurrentRefund,
  } = useRefundContext();
  const { auth, shop_setting } = useApp();

  const hasMethodOnlyActivated = useMemo(
    () =>
      current_shopping_cart?.payment_methods?.some(
        (m) => m.enable && m.hasOnly,
      ),
    [current_shopping_cart?.payment_methods],
  );

  if (!current_shopping_cart?.payment_methods) return null;
  const { payment_methods } = current_shopping_cart;

  function handleOnChangeMethodAmount(
    value,
    type,
    isAuthorizationCode = false,
  ) {
    const new_payment_methods = _.cloneDeep(payment_methods);

    let currentPaymentMethodIndex = new_payment_methods.findIndex(
      (method) => method.type === type,
    );
    if (currentPaymentMethodIndex !== -1) {
      if (isAuthorizationCode) {
        new_payment_methods[currentPaymentMethodIndex].transaction_id = value;
      } else {
        if (
          !current_shopping_cart?.lineItems?.length &&
          (value > current_refund.priceUserCanRefund ||
            (value > current_refund.priceUserCanRefund - 1 &&
              value < current_refund.priceUserCanRefund))
        ) {
          value = current_refund.priceUserCanRefund;
        }
        new_payment_methods[currentPaymentMethodIndex].amount =
          validGatewayAmountForRefund(type, value);
        if (!current_shopping_cart?.lineItems?.length) {
          updateCurrentRefund({
            priceUserRefund: value,
          });
        }
      }
    }

    updateCurrentCart({ payment_methods: new_payment_methods });
  }

  function handleSelectPaymentMethod(type) {
    let currentPaymentMethods = [...payment_methods];
    const oldMethodIndex = currentPaymentMethods.findIndex(
      (current) => current.type === method.type,
    );
    const oldMethod = currentPaymentMethods[oldMethodIndex];
    const newMethodIndex = currentPaymentMethods.findIndex(
      (current) => current.type === type,
    );
    const newMethod = currentPaymentMethods[newMethodIndex];
    const codMethodActivated = currentPaymentMethods.find(
      (current) => current.type === "cod" && current.enable,
    );

    if (
      method.type === "cod" ||
      codMethodActivated ||
      !current_shopping_cart?.lineItems?.length ||
      current_shopping_cart?.summaries?.total_payment <=
        current_refund?.priceUserRefund
    ) {
      currentPaymentMethods = currentPaymentMethods.map((current) => ({
        ...current,
        enable: false,
      }));
    }
    if (newMethod.hasOnly) {
      currentPaymentMethods = currentPaymentMethods.map((current) => ({
        ...current,
        enable: current.hasOnly ? false : current.enable,
        amount: current.hasOnly ? 0 : current.amount,
      }));
    }

    if (oldMethodIndex !== -1) {
      currentPaymentMethods[oldMethodIndex] = {
        ...oldMethod,
        enable: false,
        amount: 0,
      };
    }

    if (newMethodIndex !== -1) {
      let amount = +oldMethod.amount;
      if (newMethod.type === "cod") {
        amount = 0;
      } else if (oldMethod.type === "cod") {
        amount = +current_shopping_cart?.summaries?.total_payment || 0;
      } else if (newMethod.type !== "cash" && oldMethod.type === "cash") {
        const totalMoneyPaidByCustomer = sumBy(
          currentPaymentMethods.filter((method) => method.enable),
          (method) => method.amount,
        );
        amount =
          totalMoneyPaidByCustomer <
          current_shopping_cart?.summaries?.subtotal -
            (current_refund.priceUserRefund + current_refund.refundRedeemAmount)
            ? current_shopping_cart?.summaries?.subtotal -
              (current_refund.priceUserRefund +
                current_refund.refundRedeemAmount) -
              totalMoneyPaidByCustomer
            : 0;
        // let value = current_refund.priceUserRefund;

        // trường hợp mua mới
        if (
          current_shopping_cart.lineItems &&
          current_shopping_cart.lineItems.length > 0
        ) {
          amount = validGatewayAmountForRefund(newMethod.type, amount);
        } else {
          // trường hợp k mua mới
          amount = validGatewayAmountForRefund(
            newMethod.type,
            current_refund?.priceUserRefund,
          );
        }
      } else {
        amount = +oldMethod.amount;
      }

      currentPaymentMethods[newMethodIndex] = {
        ...newMethod,
        enable: true,
        amount: amount,
        index: oldMethod.index,
      };
    }
    updateCurrentCart({ payment_methods: currentPaymentMethods });
  }

  /**
   * Validate số tiền user nhập
   */
  const validGatewayAmountForRefund = (type, amount) => {
    let amountMax = AMOUNT_MAX;
    const gwAmount = _.cloneDeep(
      current_shopping_cart,
    )?.payment_methods?.reduce((total, method) => {
      if (
        method.type !== "cash" &&
        method.type !== "point" &&
        method.type !== type
      ) {
        return total + method.amount;
      }
      return total;
    }, 0);
    if (current_shopping_cart?.lineItems?.length) {
      if (type === "cash") {
        if (amount > amountMax) {
          amount = amountMax;
        } else {
          let refundCashMax = 0;
          if (
            current_refund?.priceUserRefund <
            current_shopping_cart?.summaries?.total_payment
          ) {
            refundCashMax = +Number(
              current_shopping_cart?.summaries?.total_payment -
                current_refund.priceUserRefund -
                (current_refund.refundRedeemAmount
                  ? current_refund.refundRedeemAmount
                  : 0) -
                gwAmount,
            ).toFixed(4);
          } else {
            refundCashMax = +Number(
              current_refund.priceUserRefund -
                current_shopping_cart?.summaries?.total_payment,
            ).toFixed(4);
          }
          if (refundCashMax < 0 && shop_setting.notAllowRefundIfLowerPrice) {
            refundCashMax = 0;
          }
          if (amount > refundCashMax - 1 && amount < refundCashMax) {
            amount = toNumber(refundCashMax);
          }
        }
      } else {
        if (
          current_refund?.priceUserRefund <
          current_shopping_cart?.summaries?.total_payment
        ) {
          amountMax = +Number(
            current_shopping_cart?.summaries?.total_payment -
              current_refund.priceUserRefund -
              (current_refund.refundRedeemAmount
                ? current_refund.refundRedeemAmount
                : 0) -
              gwAmount,
          ).toFixed(4);
        } else {
          amountMax = +Number(
            current_refund.priceUserRefund -
              current_shopping_cart?.summaries?.total_payment,
          ).toFixed(4);
        }
        if (amountMax < 0 && shop_setting.notAllowRefundIfLowerPrice) {
          amountMax = 0;
        }
      }
    } else {
      amountMax = current_refund.priceUserCanRefund;
    }

    amount = toNumber(amount);
    if (amount > amountMax || (amount > amountMax - 1 && amount < amountMax)) {
      amount = amountMax;
    }

    return amount;
  };

  const hasOnlyPaymentMethod =
    current_shopping_cart.payment_methods.filter((item) => item.enable === true)
      .length === 1;

  return (
    <div className="l-pt-4 l-pb-4">
      <div className="d-flex align-items-center">
        <div className="flex-auto w-51">
          <SelectionCustom
            value={method.type}
            options={payment_methods}
            idField="type"
            valueField="d_name"
            onChange={(type) => handleSelectPaymentMethod(type)}
            width={width}
            prefix={
              method.isCustom ? (
                <HrvComponents.Avatar
                  arrColor="gray"
                  className="text-primary d-flex section-20"
                  shape="circle"
                  content={method.name}
                />
              ) : (
                <Logo
                  name={
                    method.isCustom
                      ? "account_balance_wallet"
                      : `${method.type}_xs`
                  }
                  size="xs"
                  className="d-flex"
                />
              )
            }
            disabled={
              finalPrice() === 0 && current_shopping_cart?.lineItems?.length
            }
          >
            {payment_methods
              .filter((m) => !m.isHidden && !m.isQR && !m.isPOS)
              .map((m) => {
                const isDisabled =
                  (!method.hasOnly && m.hasOnly && hasMethodOnlyActivated) ||
                  (m.type !== method.type && m.enable) ||
                  (!current_shopping_cart.isShipping && m.type === "cod");
                return (
                  <SelectionCustom.Option
                    key={m.type}
                    value={m.type}
                    disabled={isDisabled}
                    hidden={m.type === "cod"}
                  >
                    <div className="d-flex align-items-center">
                      <div
                        className={`d-flex justify-content-center mr-3 background-tertiary rounded-circle p-1 cursor-pointer`}
                        style={{ width: "40px", height: "40px" }}
                      >
                        {m.isCustom ? (
                          <HrvComponents.Avatar
                            arrColor="gray"
                            className="text-primary mx-1 d-inline-block"
                            shape="circle"
                            content={m.name}
                          />
                        ) : (
                          <Logo
                            name={m.type}
                            size={"md"}
                            className={"align-center"}
                          />
                        )}
                      </div>
                      {m.d_name}
                    </div>
                  </SelectionCustom.Option>
                );
              })}
          </SelectionCustom>
        </div>
        <div className="l-ml-4 l-mr-4">
          {method.hasAuthorizationCode && (
            <PaymentAuthCode
              customCss="auth-code-btn"
              code={method.transaction_id}
              onUpdate={(value) =>
                handleOnChangeMethodAmount(value, method.type, true)
              }
            />
          )}
        </div>
        <div className="w-25 d-flex flex-row flex-stretch">
          <InputNumber
            currency
            value={Math.round(method.amount)}
            onChange={(value) => {
              handleOnChangeMethodAmount(value, method.type);
            }}
            disabled={
              method.type === "cod" ||
              (finalPrice() === 0 && current_shopping_cart?.lineItems?.length)
            }
          />
          {finalPrice() > 0 ||
          (finalPrice() === 0 && !current_shopping_cart?.lineItems?.length) ||
          hasOnlyPaymentMethod ? null : (
            <div
              className="flex-auto cursor-pointer btn-close l-ml-8 align-self-center"
              onClick={handleRemovePaymentMethod}
              style={{ height: "max-content", padding: "0.2rem" }}
            >
              <Icon name="close" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
