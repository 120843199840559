import { useApp } from "contexts/app";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { sumBy, toNumber } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { concatString } from "screens-v2/carts-v2/utils";
import { useRefundContext } from "..";
import { ModalConfirmPartialPayment } from "./components/modalConfirmPartialPayment";
import { ModalPaymentMethods } from "./components/modalPaymentMethods";
import { ModalRefundOrderSuccess } from "./components/modalRefundOrderSuccess";
import { Customers } from "./customers";
import { PaymentSummary } from "./paymentSummary";
import "./styles.css";
import { checkRefundedPoint } from "../refund.service";
import { getLoyaltyProfile } from "api/customer-repository";
import { PricingList } from "./pricingList";
import { IS_OPEN_PRICING_LIST } from "utils/whiteListShop";
import { SYSTEM_GUEST_EMAIL } from "infrastructures/utils";
import { VAT, VATContext } from "screens-v2/refunds-v2/vat";
import { AddressDelivery } from "./customers/addressDelivery";
import { RefundPaymentBottomRight } from "./Payments/RefundPaymentBottomRight";
import {
  submitCheckoutSession,
  updateCheckoutSession,
} from "api/checkout-repository";
import {
  fulfillmentAsyncOrder,
  processingDiscover,
} from "screens-v2/carts-v2/cart-helpers.service";
import { callGetApiOrderDetails, confirmOrder } from "api/order-repository";
import { useShift } from "contexts/shift/shift-context";
import { SessionScreenType } from "models/constants";

export const saleModes = {
  inPoint: "inPoint",
  delivery: "delivery",
};

export const RightCart = () => {
  const appContext = useApp();
  const { shift } = useShift();
  const refPaymentSummary = useRef(null);
  const refPaymentBottomRight = useRef(null);
  const refRightMainCover = useRef(null);
  const [showPayment, setShowPayment] = useState(false);
  const [hasQRPayment, setHasQRPayment] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalPaymentPartial, setShowModalPaymentPartial] = useState(false);
  const { showGlobalToast } = useLayoutContext();
  const intl = useIntl();
  const {
    current_shopping_cart,
    salesMan,
    updateCurrentCart,
    isSubmitRefundOrderWaiting,
    setIsSubmitRefundOrderWaiting,
    current_refund,
    setBlockingPage,
    finalPrice,
    updateCurrentRefund,
  } = useRefundContext();
  const [openRemoveVat, setOpenRemoveVat] = useState(false);
  const [openPopupVat, setOpenPopupVat] = useState(false);
  const [showEditAddress, setShowEditAddress] = useState(false);

  const { shop_info: shopInfo } = useApp();

  useEffect(() => {
    getEinvoice();
  }, []);

  const getEinvoice = async () => {
    try {
      const location = appContext.current_location;
      let url = `/call/com_api/einvoice_settings/location_settings?location_id=${location.id}&channel=pos`;
      const response = await appContext.callAPI("get", url);
      const data = response.data ?? [];
      updateCurrentRefund({ eInvoice: data });
    } catch (error) {
      console.log("einvoice error: ", error);
    }
  };

  const payment_methods_active = useMemo(() => {
    setHasQRPayment(
      current_shopping_cart?.payment_methods?.some(
        (method) => method.enable && method.isQR,
      ),
    );
    return current_shopping_cart?.payment_methods?.filter(
      (method) => method.enable,
    );
  }, [current_shopping_cart?.payment_methods]);

  // tổng tiền khách đưa
  const totalMoneyPaidByCustomer = useMemo(() => {
    if (payment_methods_active?.length) {
      return +Number(
        sumBy(payment_methods_active, (method) => method.amount),
      )?.toFixed(4);
    } else {
      return 0;
    }
  }, [payment_methods_active]);

  const isCod = useMemo(() => {
    return payment_methods_active?.find((method) => method.type === "cod")
      ?.enable;
  }, [payment_methods_active]);

  const validOrderBeforeSubmit = (isPaymentOnline = false) => {
    try {
      const location = appContext.current_location;
      if (!location) {
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.Vui lòng chọn chi nhánh bán hàng`,
            defaultMessage: "Vui lòng chọn chi nhánh bán hàng",
          }),
        );
        return false;
      }
      if (!salesMan) {
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.Vui lòng chọn nhân viên bán hàng`,
            defaultMessage: "Vui lòng chọn nhân viên bán hàng",
          }),
        );
        return false;
      }
      if (isCod && !current_shopping_cart?.customer) {
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.Vui lòng chọn khách hàng`,
            defaultMessage: "Vui lòng chọn khách hàng",
          }),
        );
        return false;
      }

      if (!payment_methods_active?.length && !isPaymentOnline) {
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.Vui lòng chọn phương thức thanh toán`,
            defaultMessage: "Vui lòng chọn phương thức thanh toán",
          }),
        );
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  };
  async function postOrderPayment(
    orderData,
    isConfirmOrder = true,
    isFulfillAsync = true,
  ) {
    const mergedOrderData = {
      ...orderData,
      ...(!current_shopping_cart.isShipping && {
        orderProducts: orderData?.orderProducts?.map((p, index) => ({
          ...p,
          lots: current_shopping_cart.lineItems?.find(
            (l) => l.variantId === p.productVariantsId && l.seq === index,
          )?.lots,
        })),
      }),
    };
    if (appContext.shop_setting.isConfirmOrderEnable && isConfirmOrder) {
      confirmOrder(appContext.callAPI, orderData?.orderId);
    }
    if (!current_shopping_cart.isShipping && isFulfillAsync) {
      fulfillmentAsyncOrder(
        mergedOrderData,
        current_shopping_cart.isShipping,
        appContext,
        showGlobalToast,
      );
    }
  }

  /**
   * Thực hiện trả hàng
   * Áp dụng 1 phương thức hoàn trả
   * Lưu log phương thức hoàn trả vào ca
   */
  const onRefundOrder = async (isPaymentOnline = false) => {
    if (!isSubmitRefundOrderWaiting) {
      try {
        setIsSubmitRefundOrderWaiting(true);
        if (current_shopping_cart?.lineItems?.length) {
          return await onRefundAndNewOrder();
        }

        const location = appContext.current_location;
        if (!validOrderBeforeSubmit(isPaymentOnline)) {
          setIsSubmitRefundOrderWaiting(false);
          return;
        }

        let orderProducts = current_refund?.lineItems.slice();
        orderProducts.forEach((item) => {
          if (item.isTrackingInventory) {
            //nhập kho
            item.returnableQuantity = item.refundNumber;
          } else {
            item.restockableQuantity = item.refundNumber;
            item.returnableQuantity = item.refundNumber;
          }
        });

        var transactions = [];
        let shiftTransactions = [];

        if (current_refund.priceUserRefund > 0) {
          //Trả hàng
          const gateway = current_shopping_cart.payment_methods.find(
            (method) => method.enable,
          );
          const amount =
            gateway.amount > current_refund.priceUserRefund ||
            (gateway.amount > current_refund.priceUserRefund - 1 &&
              gateway.amount < current_refund.priceUserRefund)
              ? current_refund.priceUserRefund
              : gateway.amount;
          transactions = [
            {
              transactionName: gateway.name,
              paymentMethodName: gateway.name,
              amountTransaction: amount,
              gateway: gateway.name,
              externalTransactionId: gateway.transaction_id || "",
            },
          ];

          //Log ca
          if (gateway.type !== "cod") {
            shiftTransactions.push({
              gatewayName: gateway.type,
              amount: gateway.amount,
            });
          }
        }
        const { orderNumber, source } = current_refund?.order;
        let refundOrderModel = {
          orderModel: {
            locationId: location.id,
            isRestockItem: true,
            isReturnItem: true,
            totalRefunded: +Number(current_refund.priceUserRefund).toFixed(4),
            transactions: transactions,
            refundNote: current_shopping_cart.note || "",
            Source: source || "online",
            OrderNumber: orderNumber,
          },
          listOrderProducts: orderProducts,
          transactions: shiftTransactions,
        };

        const result = await appContext.callAPI(
          "put",
          `/call/retail_api/order/${current_refund?.order?.orderId}/refund`,
          refundOrderModel,
        );
        if (result.status === 200) {
          if (!result?.errors?.length) {
            setIsSubmitRefundOrderWaiting(false);
            setShowPayment(false);
            setBlockingPage(false);
            setTimeout(() => {
              setShowModalSuccess({
                type: true,
                data: {
                  orderNumber: current_refund?.order?.orderNumber,
                  orderId: current_refund?.order?.orderId,
                  refundOrderModel: refundOrderModel,
                  current_refund: current_refund,
                },
              });
            }, 10);
          } else {
            //Hoàn trả đơn hàng thất bại
            setIsSubmitRefundOrderWaiting(false);
            const errorMsg =
              result.errors[0] || "Hoàn trả đơn hàng không thành công!";
            showGlobalToast(
              "error",
              <FormattedMessage
                id={`notifications.${errorMsg}`}
                defaultMessage={errorMsg}
              />,
            );
          }
        } else {
          setIsSubmitRefundOrderWaiting(false);
        }
      } catch (e) {
        setIsSubmitRefundOrderWaiting(false);
        showGlobalToast(
          "error",
          <FormattedMessage
            id="notifications.Hoàn trả đơn hàng không thành công!"
            defaultMessage="Hoàn trả đơn hàng không thành công!"
          />,
        );
      }
    }
  };

  /**
   * Tạo đơn hàng mới sau khi refund
   */
  const sendNewOrderAfterRefund = async (
    refOrderId,
    refundOrderModel,
    totalRefund,
  ) => {
    try {
      const location = appContext.current_location;

      if (!payment_methods_active?.length) {
        showGlobalToast(
          "error",
          <FormattedMessage
            id="notifications.Vui lòng chọn phương thức thanh toán"
            defaultMessage="Vui lòng chọn phương thức thanh toán"
          />,
        );
        return;
      }

      const sessionId = current_shopping_cart?.id;
      if (!sessionId) {
        return;
      }

      const { user } = appContext.auth;
      const currentOrderLines = (current_shopping_cart?.lineItems).map(
        (order) => {
          return {
            ...order,
            discountAmount: +Number(order.discountAmount).toFixed(4),
            lots:
              !isCod && !current_shopping_cart?.isShipping ? order.lots : null,
          };
        },
      );

      //Redeem model
      const { redeemDiscountType, customerId } = current_refund?.order;
      let redeemModel = {};
      if (
        redeemDiscountType !== "percent" &&
        current_refund?.refundRedeemAmount
      ) {
        const customerProfile = await getLoyaltyProfile(
          appContext,
          customerId,
          false,
        );
        const { payload } = customerProfile;
        if (payload) {
          const refundRedeemAmount = current_refund?.refundRedeemAmount;
          redeemModel = {
            redeemServiceName: payload.name,
            redeemPayloadId: payload.id,
            redeemDiscountType: payload.discount_type || 0,
            redeemDiscount: payload.discount || 0,
            redeemMaxPerOrder: payload.max_per_order || 0,
            redeemUsedAmount: refundRedeemAmount,
            redeemAmount: payload.amount,
          };
        }
      }
      const summaries = current_shopping_cart?.summaries;
      const totalDiscount = +Number(
        current_shopping_cart?.orderDiscountSelected?.discountAmount || 0,
      ).toFixed(4);

      let hasEinvoice = true;
      if (
        !shopInfo.taxable ||
        !current_refund?.taxData?.eInvoiceInfo ||
        !current_refund?.eInvoice ||
        current_refund?.eInvoice?.length <= 0
      ) {
        hasEinvoice = false;
      }

      const billingModel = hasEinvoice
        ? {
            billingCompany:
              current_refund.taxData?.billingCompany ||
              concatString({
                value: [
                  current_shopping_cart?.customer?.firstName,
                  current_shopping_cart?.customer?.lastName,
                ],
              }),
            billingTaxCode: current_refund.taxData?.billingTaxCode || null,
            billingEmail: current_refund.taxData?.billingEmail || null,
            billingAddress: current_refund.taxData?.billingAddress || null,
            billingCountry:
              current_refund.taxData?.address?.billingCountry || null,
            billingCountryName:
              current_refund.taxData?.address?.billingCountryName || null,
            billingProvince:
              current_refund.taxData?.address?.billingProvince || null,
            billingProvinceName:
              current_refund.taxData?.address?.billingProvinceName || null,
            billingDistrict:
              current_refund.taxData?.address?.billingDistrict || null,
            billingDistrictName:
              current_refund.taxData?.address?.billingDistrictName || null,
            billingWard: current_refund.taxData?.address?.billingWard || null,
            billingWardName:
              current_refund.taxData?.address?.billingWardName || null,
            billingCity: current_refund.taxData?.address?.city || null,
            billingZipCode: current_refund.taxData?.address?.zipCode || null,
            eInvoiceInfo: {
              company: current_refund.taxData?.eInvoiceInfo?.company || false,
              name: current_refund.taxData?.eInvoiceInfo?.name || "",
              taxCode: current_refund.taxData?.eInvoiceInfo?.taxCode || "",
              address: current_refund.taxData?.eInvoiceInfo?.address || "",
              email: current_refund.taxData?.eInvoiceInfo?.email || "",
              save: current_refund.taxData?.eInvoiceInfo?.save || false,
            },
          }
        : {
            billingCompany: null,
            billingTaxCode: null,
            billingEmail: null,
            billingAddress: null,
            billingCountry: null,
            billingCountryName: null,
            billingProvince: null,
            billingProvinceName: null,
            billingDistrict: null,
            billingDistrictName: null,
            billingWard: null,
            billingWardName: null,
            billingCity: null,
            billingZipCode: null,
            eInvoiceInfo: null,
          };

      let orderModel = {
        ...redeemModel,
        ...billingModel,
        userId: salesMan.id,
        locationId: location.id,
        locationName: location.name,
        assignedLocationId: location.id,
        assignedLocationName: location.name,
        orderShippingFee: summaries.shipping_fee,
        orderNotes: current_shopping_cart.orderNote,
        note_Attributes: current_shopping_cart.noteAttributes,
        orderProducts: currentOrderLines,
        ...current_shopping_cart?.orderDiscountSelected,
        discountAmount: totalDiscount,
        isCodGateway: isCod,
        totalDiscounts: totalDiscount,
        sourceName: "pos",
        source: "pos",
        send_webhooks: true,
        fulFillMentStatusId:
          !isCod && !current_shopping_cart.isShipping ? 1 : 0,
        fulfilledDate: null,
      };

      const orderCustomer = { ...current_shopping_cart?.customer };
      let customerAddress = null;

      if (orderCustomer) {
        if (orderCustomer?.customerAddress?.length) {
          customerAddress = orderCustomer.customerAddress.find(
            (address) => address.isDefault,
          );
        }
        orderModel.customerId = orderCustomer.id;
        orderModel.customerName = `${concatString({
          value: [orderCustomer.firstName, orderCustomer.lastName],
        })}`;
        orderModel.customerEmail = orderCustomer.email;
        orderModel.shippingFirstName = orderCustomer.firstName || "";
        orderModel.shippingLastName = orderCustomer.lastName || "";
        orderModel.shippingPhone = orderCustomer.phone || "";
        if (
          !orderModel.shippingFirstName &&
          !orderModel.shippingLastName &&
          orderModel.customerId &&
          orderModel.customerEmail !== SYSTEM_GUEST_EMAIL
        ) {
          orderModel.shippingFirstName = "--";
        }
        if (customerAddress) {
          orderModel.shippingAddress =
            customerAddress.address1 || customerAddress.address2;
          orderModel.shippingProvinceCode = customerAddress.provinceCode;
          orderModel.shippingDistrictCode = customerAddress.districtCode;
          orderModel.shippingWardCode = customerAddress.wardCode;
          orderModel.shippingCountryCode = customerAddress.countryCode;
          orderModel.shippingDistrict = customerAddress.districtId;
          orderModel.shippingProvince = customerAddress.provinceId;
          orderModel.shippingCountry = customerAddress.countryId;
          orderModel.shippingWard = customerAddress.wardId;
        }
      }

      let shiftTransactions = [];
      //transactions
      let transactions = [];

      //Tổng tiền đơn hàng mới
      const new_order_price_total = summaries?.total_payment;
      //Trường hợp shop trả tiền lại cho khách
      //Đơn hàng mua mới có giá trị nhỏ hơn đơn hàng hoàn trả
      //Áp dụng hoàn trả một phương thức

      const amountPaidByCustomerWithoutCash = sumBy(
        payment_methods_active,
        function (method) {
          if (method.type === "cash") {
            return 0;
          } else {
            return method.amount;
          }
        },
      );
      if (current_refund.priceUserRefund > new_order_price_total) {
        const gateway = current_shopping_cart.payment_methods.find(
          (method) => method.enable,
        );

        if (gateway) {
          transactions.push({
            gateway: "Tiền mặt",
            transactionAmount: new_order_price_total,
            locationId: location.id,
            CreatedUser: user.id,
            kind: "capture",
          });
          shiftTransactions.push({
            gatewayName: "cash",
            amount: new_order_price_total,
          });
        }
      } else {
        //Trường hợp khách trả tiền cho shop
        //Áp dụng nhiều phương thức thanh toán

        const tien_khach_dua =
          totalMoneyPaidByCustomer + current_refund.priceUserRefund;
        const tien_thua_tra_khach =
          totalMoneyPaidByCustomer -
          (summaries?.total_payment -
            current_refund?.priceUserRefund +
            current_refund?.redeemUsedAmount);

        orderModel.noteAttributes = [
          { name: "Tiền khách đưa", value: tien_khach_dua.toString() },
          {
            name: "Tiền thừa trả khách",
            value: tien_thua_tra_khach.toString(),
          },
        ];

        transactions = payment_methods_active
          .map((method) => {
            if (!method.enable || toNumber(method.amount) <= 0) return null;
            if (method.type === "cash") {
              const maxCashReceive = +Number(
                summaries.total_payment - amountPaidByCustomerWithoutCash,
              ).toFixed(4);
              return {
                gateway: method.name,
                transactionAmount: maxCashReceive,
                externalPaymentTransaction: method.transaction_id,
                locationId: location.id,
                createdUser: user.id,
                kind: "capture",
              };
            } else {
              return {
                gateway: method.name,
                transactionAmount: method.amount,
                externalPaymentTransaction: method.transaction_id,
                locationId: location.id,
                createdUser: user.id,
                kind: "capture",
              };
            }
          })
          .filter((item) => item?.transactionAmount);

        shiftTransactions = payment_methods_active
          .map((method) => {
            if (!method.enable || method.amount <= 0) return null;
            return {
              gatewayName: method.type,
              amount: method.amount,
              note: method.transaction_id,
            };
          })
          .filter((item) => item);

        if (
          !transactions?.find(
            (transaction) => transaction.gateway === "Tiền mặt",
          )
        ) {
          transactions.push({
            gateway: "Tiền mặt",
            transactionAmount: totalRefund,
            externalTransactionId: "",
            locationId: location.id,
            createdUser: user.id,
            kind: "capture",
          });
          shiftTransactions.push({ gatewayName: "cash", amount: totalRefund });
        }
      }

      orderModel.orderTransactions = transactions;
      if (transactions.length === 0) {
        orderModel.PaymentStatusName = "paid";
      } else {
        orderModel.gateway = transactions[0].gateway;
      }
      orderModel.refOrderId = refOrderId;

      let newOrderModel = {
        isSendFulfillmentReceipt: false,
        orderModel: orderModel,
        Transactions: shiftTransactions,
        orderSource: "pos",
        requestEInvoice:
          hasEinvoice &&
          (current_refund?.taxData?.eInvoiceInfo?.name ||
            current_refund?.taxData.billingCompany)
            ? true
            : false,
        CreateAndPublishEInvoice: hasEinvoice,
      };
      let isHasShiftManager = false;
      let attributes =
        [
          ...current_shopping_cart.noteAttributes,
          ...current_shopping_cart.attributes,
        ] || null;
      if (shift.current_shift?.shiftManager?.id) {
        if (!attributes) attributes = [];
        let idx = attributes.findIndex(
          (x) => x.key === "x-haravan-pos-supervisor",
        );
        if (idx === -1) {
          attributes.push({
            key: "x-haravan-pos-supervisor",
            value: shift.current_shift.shiftManager.id + "",
          });
        } else {
          attributes[idx] = {
            key: "x-haravan-pos-supervisor",
            value: shift.current_shift.shiftManager.id + "",
          };
        }
        isHasShiftManager = true;
      }
      if (current_refund?.eInvoice.length > 0)
        newOrderModel.EInvoiceLocSettingId = current_refund?.eInvoice[0].id;
      const activeQRMethod = payment_methods_active.find(
        (m) => m.isQR && m.enable,
      );
      const updateRes = await updateCheckoutSession(
        appContext.callAPI,
        sessionId,
        {
          ...(hasQRPayment
            ? {
                paymentMethodId:
                  current_shopping_cart?.paymentMethods?.find(
                    (method) =>
                      method.sysTypeId ===
                      activeQRMethod.paymentInstructionTypeId,
                  )?.id || 0,
                paymentMethodName: null,
              }
            : {
                paymentMethodName:
                  current_shopping_cart?.payment_methods?.find(
                    (method) => method.enable && !method.isHidden,
                  )?.name || "Tiền mặt",
              }),
          ...(current_shopping_cart?.customer?.id && {
            customerId: current_shopping_cart?.customer?.id,
          }),
          sendReceipt: appContext.shop_setting?.isSendEmailConfirm,
          sendNotify: true,
          note: current_shopping_cart.orderNote,
          ...(isHasShiftManager && {
            attributes: attributes,
          }),
        },
      );
      const errorCoupons = current_shopping_cart?.discountAllocations?.reduce(
        (coupons, cur) => {
          const isExist = updateRes?.discountAllocations?.find(
            (d) => d.name === cur?.name,
          );
          if (cur?.name && !isExist) {
            coupons.push(cur.name);
          }
          return coupons;
        },
        [],
      );
      if (updateRes?.hasError || !!errorCoupons?.length) {
        const orderErrors = [...updateRes.errors, ...updateRes.userErrors];
        const errorMsg = !!errorCoupons?.length
          ? `Mã ${errorCoupons?.join(", ")} không hợp lệ`
          : orderErrors[0]?.message || "Có gì đó không ổn";
        setIsSubmitRefundOrderWaiting(false);
        await processingDiscover({
          currentCart: { ...current_shopping_cart },
          newCart: updateRes,
          showGlobalToast,
          appContext,
          methodDefaults: current_shopping_cart.payment_methods,
          intl,
          isRefund: true,
        }).then((res) => {
          updateCurrentCart({ ...res });
        });
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.${errorMsg}`,
            defaultMessage: errorMsg,
          }),
        );
        return;
      }
      const submitSessionRes = await submitCheckoutSession(appContext.callAPI, sessionId, {
        locationId: location.id,
        screenType: SessionScreenType.REFUND
      });
      const result = submitSessionRes?.data;
      if (result?.hasError || submitSessionRes.errors) {
        const orderErrors = [...result.errors?.filter((err) => (err?.field !== 'Lines' && err?.field !== 'Inventory')), ...result.userErrors, submitSessionRes?.errorCodes]
        let notifyMsg =
          orderErrors?.length &&
          orderErrors[0]?.message ===
            "Không thể sử dụng chung với các khuyến mãi khác."
            ? "Chương trình Khách hàng thân thiết không cho phép dùng chung với Khuyến mãi khác"
            : orderErrors[0]?.message;
        const errorMsg =
          (orderErrors?.length && orderErrors[0]?.message) ||
          notifyMsg ||
          "Thanh toán không thành công";
        setIsSubmitRefundOrderWaiting(false);
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.${errorMsg}`,
            defaultMessage: errorMsg,
          }),
        );
        return;
      } else {
        const orderTransactions = transactions
          .map((t) => {
            return {
              gateway: t.gateway,
              amount: t.transactionAmount,
              externalTransactionId: t.externalPaymentTransaction,
              locationId: location.id,
              userId: user.id,
            };
          })
          .filter((item) => item?.amount);
        if (!isCod && !!orderTransactions?.length && !hasQRPayment) {
          const transactionRes = await appContext.callAPI(
            "post",
            `/call/com_api/pos/orders/${result?.order?.id}/transactions`,
            orderTransactions,
          );
          if (
            !transactionRes ||
            !!transactionRes.errors?.length ||
            !transactionRes.data
          ) {
            let errorMsg = "Thanh toán không thành công";
            if (!!transactionRes?.errors?.length) {
              errorMsg = transactionRes.errors[0];
            }
            setIsSubmitRefundOrderWaiting(false);
            showGlobalToast(
              "error",
              intl.formatMessage({
                id: `notifications.${errorMsg}`,
                defaultMessage: errorMsg,
              }),
            );
            return;
          }
        }
        if (!current_shopping_cart?.total) {
          const confirmPaymentRes = await appContext.callAPI(
            "put",
            `/call/com_api/orders/${result?.order?.id}/pay`,
            transactions,
          );
          if (!!confirmPaymentRes.errors?.length || !confirmPaymentRes.data) {
            const errorMsg =
              confirmPaymentRes.errors[0] || "Thanh toán không thành công";
            setIsSubmitRefundOrderWaiting(false);
            showGlobalToast(
              "error",
              intl.formatMessage({
                id: `notifications.${errorMsg}`,
                defaultMessage: errorMsg,
              }),
            );
            return;
          }
        }
        const order = await callGetApiOrderDetails(
          appContext.callAPI,
          result?.order?.id,
        );
        if (!!order?.data && !hasQRPayment) {
          postOrderPayment(order?.data);
        }
        // initNewRefundCart();
        setIsSubmitRefundOrderWaiting(false);
        setShowPayment(false);
        setBlockingPage(false);
        setTimeout(() => {
          setShowModalSuccess({
            type: true,
            data: {
              orderNumber: order.data.orderNumber,
              orderId: order.data.orderId,
              refundOrderModel: refundOrderModel,
              isNewOrder: true,
              totalRefund: totalRefund,
              current_refund: current_refund,
              session: result,
            },
          });
        }, 10);
      }
    } catch (e) {
      showGlobalToast(
        "error",
        <FormattedMessage
          id="carts.orders.Thanh toán không thành công!"
          defaultMessage="Thanh toán không thành công!"
        />,
      );
    }
  };

  const onRefundAndNewOrder = async () => {
    if (!isSubmitRefundOrderWaiting) {
      try {
        setIsSubmitRefundOrderWaiting(true);
        //Valid số tiền km KHTT lớn hơn tổng tiền đơn hàng mới thì không cho đổi trả
        if (
          appContext.shop_loyalty?.enabled_refund_point &&
          current_refund.refundRedeemAmount >
            current_shopping_cart?.summaries?.total_payment
        ) {
          showGlobalToast(
            "error",
            <FormattedMessage
              id="notifications.Bạn không được phép thực hiện tính năng đổi hàng khi số tiền khuyến mãi KHTT > Số tiền đơn hàng mới"
              defaultMessage="Bạn không được phép thực hiện tính năng đổi hàng khi số tiền khuyến mãi KHTT > Số tiền đơn hàng mới"
            />,
          );
          setIsSubmitRefundOrderWaiting(false);
          return;
        }

        let refundPriceSubTotal = Math.abs(
          current_refund?.priceUserRefund +
            current_refund?.refundRedeemAmount -
            current_shopping_cart?.summaries?.total_payment,
        );
        if (
          refundPriceSubTotal < 0 &&
          appContext.shop_info.notAllowRefundIfLowerPrice
        ) {
          refundPriceSubTotal = 0;
        }

        const tien_thua_tra_khach =
          toNumber(totalMoneyPaidByCustomer) - Math.abs(toNumber(finalPrice()));
        if (tien_thua_tra_khach < 0) {
          showGlobalToast(
            "error",
            <FormattedMessage
              id="notifications.Số tiền khách đưa phải bằng hoặc lớn hơn số tiền thanh toán"
              defaultMessage="Số tiền khách đưa phải bằng hoặc lớn hơn số tiền thanh toán"
            />,
          );
          setIsSubmitRefundOrderWaiting(false);
          return;
        }

        const location = appContext.current_location;
        const isOrderGatewayEmpty =
          !current_shopping_cart?.payment_methods?.filter(
            (method) => method.enable,
          )?.length;
        if (isOrderGatewayEmpty) {
          showGlobalToast(
            "error",
            <FormattedMessage
              id="notifications.Vui lòng chọn phương thức thanh toán"
              defaultMessage="Vui lòng chọn phương thức thanh toán"
            />,
          );
          setIsSubmitRefundOrderWaiting(false);
          return;
        }

        //check lô
        if (!current_shopping_cart.isShipping) {
          const hasEmptyLots = current_shopping_cart?.lineItems?.find(
            (line) => line.lotSupport && !line.lots?.length,
          );
          if (hasEmptyLots) {
            showGlobalToast(
              "error",
              intl.formatMessage({
                id: `notifications.Lô không được bỏ trống`,
                defaultMessage: "Lô không được bỏ trống",
              }),
            );
            setIsSubmitRefundOrderWaiting(false);
            return;
          }
          const isInvalidQuantity = current_shopping_cart?.lineItems?.some(
            (product) => {
              return product?.lotSupport
                ? product.lots?.reduce(
                    (prev, curr) => prev + curr.quantity,
                    0,
                  ) !== product.quantity
                : false;
            },
          );
          if (isInvalidQuantity) {
            showGlobalToast(
              "error",
              "Số lượng của lô phải bằng số lượng cần giao hàng",
            );
            return;
          }
        }
        let orderProducts = current_refund?.lineItems.slice();
        orderProducts.forEach((item) => {
          delete item.isCustomProduct;
          if (item.isTrackingInventory) {
            //nhập kho
            item.returnableQuantity = item.refundNumber;
          } else {
            item.restockableQuantity = item.refundNumber;
            item.returnableQuantity = item.refundNumber;
          }
        });

        let transactions = [];
        let shiftTransactions = [];
        let amountRefund = 0;

        if (current_refund.priceUserRefund > 0) {
          //Tổng tiền đơn hàng mới
          const new_order_price_total =
            current_shopping_cart?.summaries?.total_payment;

          //Kiểm tra cấu hình ko hoàn tiền khi giá trị đơn hàng mua nhỏ hơn đơn trả
          //Tiền hoàn trả = tổng tiền mua đơn hàng mới
          // amountRefund = this.isRefundOrderLowerPrice ? new_order_price_total : this.priceUserRefund;
          amountRefund = +Number(current_refund?.priceUserRefund).toFixed(4);

          const paymentMethodName = "Tiền mặt";

          //Kiểm tra trường hợp shop hoàn tiền cho khách
          //Đơn hàng mua mới có giá trị nhỏ hơn đơn hàng hoàn trả
          //Áp dụng hoàn trả một phương thức
          //Lưu số tiền hoàn trả vào đơn hàng trả: this.refundPriceSubTotal
          if (current_refund?.priceUserRefund > new_order_price_total) {
            // TODO fix bug refund always apply cash
            if (appContext.shop_setting.notAllowRefundIfLowerPrice) {
              amountRefund = new_order_price_total;
            }

            const gateway = current_shopping_cart.payment_methods.find(
              (method) => method.enable,
            );
            if (gateway) {
              if (gateway.type === "cash") {
                transactions.push({
                  transactionName: paymentMethodName,
                  paymentMethodName: paymentMethodName,
                  amountTransaction: amountRefund,
                  gateway: paymentMethodName,
                });
                shiftTransactions.push({
                  gatewayName: "cash",
                  amount: amountRefund,
                });
              } else {
                transactions.push({
                  transactionName: paymentMethodName,
                  paymentMethodName: paymentMethodName,
                  amountTransaction: new_order_price_total,
                  gateway: paymentMethodName,
                });
                transactions.push({
                  transactionName: gateway.name,
                  paymentMethodName: gateway.name,
                  amountTransaction: Math.abs(
                    current_refund?.priceUserRefund +
                      current_refund?.refundRedeemAmount -
                      current_shopping_cart?.summaries?.total_payment,
                  ),
                  gateway: gateway.name,
                  externalTransactionId: gateway.transaction_id || "",
                });

                shiftTransactions.push({
                  gatewayName: "cash",
                  amount: new_order_price_total,
                });
                //Log ca
                if (gateway.type !== "cod") {
                  shiftTransactions.push({
                    gatewayName: gateway.type,
                    amount: gateway.amount,
                  });
                }
              }
            } else {
              transactions.push({
                transactionName: paymentMethodName,
                paymentMethodName: paymentMethodName,
                amountTransaction: new_order_price_total,
                gateway: paymentMethodName,
              });
              shiftTransactions.push({
                gatewayName: "cash",
                amount: new_order_price_total,
              });
            }
          } else {
            //Trường hợp khách thanh toán đơn hàng mới
            //lưu phương thước thanh toán đơn trả
            //Default phương thức hoàn trả là tiền mặt
            transactions.push({
              transactionName: paymentMethodName,
              paymentMethodName: paymentMethodName,
              amountTransaction: amountRefund,
              gateway: paymentMethodName,
            });
            shiftTransactions.push({
              gatewayName: "cash",
              amount: amountRefund,
            });
          }
        }
        const { orderNumber, source, orderId } = current_refund.order;
        let refundOrderModel = {
          orderModel: {
            locationId: location.id,
            isRestockItem: true,
            isReturnItem: true,
            totalRefunded: 0,
            transactions: transactions,
            refundNote: current_shopping_cart?.note || "",
            Source: source || "online",
            OrderNumber: orderNumber,
          },
          listOrderProducts: orderProducts,
          transactions: shiftTransactions,
        };

        const result = await appContext.callAPI(
          "put",
          `/call/retail_api/order/${orderId}/refund`,
          refundOrderModel,
        );
        if (result.status === 200) {
          if (!result.error && !result.errors) {
            const refOrderId = orderId;
            const refOrderNumber = orderNumber;
            const refundId = result.data.id;
            if (
              appContext.shop_loyalty?.enabled_refund_point &&
              current_refund.refundRedeemAmount > 0
            ) {
              await checkRefundedPoint(
                0,
                refOrderId,
                refundId,
                async (isLoyaltyCheck) => {
                  if (isLoyaltyCheck) {
                    //Tạo đơn hàng mới
                    await sendNewOrderAfterRefund(
                      refOrderId,
                      refundOrderModel,
                      amountRefund,
                    );
                    setIsSubmitRefundOrderWaiting(false);
                  } else {
                    //tao dh that bai
                    setIsSubmitRefundOrderWaiting(false);
                    const errorMsg = "Tạo mới đơn hàng không thành công!";
                    showGlobalToast("error", errorMsg);
                  }
                },
                appContext,
              );
            } else {
              //Tạo đơn hàng mới
              updateCurrentCart({
                loyalty: {
                  ...current_shopping_cart?.loyalty,
                  redeemUsedAmount: current_refund?.refundRedeemAmount,
                  payment_methods: current_shopping_cart.payment_methods,
                },
              });
              await sendNewOrderAfterRefund(
                refOrderId,
                refundOrderModel,
                amountRefund,
                refOrderNumber,
              );
              setIsSubmitRefundOrderWaiting(false);
            }
          } else {
            //Hoàn trả đơn hàng thất bại
            setIsSubmitRefundOrderWaiting(false);
            const errorMsg =
              result.errors[0] || "Hoàn trả đơn hàng không thành công!";
            showGlobalToast("error", errorMsg);
          }
        } else {
          setIsSubmitRefundOrderWaiting(false);
        }
      } catch (e) {
        setIsSubmitRefundOrderWaiting(false);
        showGlobalToast(
          "error",
          <FormattedMessage
            id="notifications.Hoàn trả đơn hàng không thành công!"
            defaultMessage="Hoàn trả đơn hàng không thành công!"
          />,
        );
      }
    }
  };

  const isShowAction =
    shopInfo.taxable ||
    (!appContext?.shop_setting?.hideOrderShippingState &&
      !current_shopping_cart?.isShipping);

  return (
    <>
      <div
        className="d-flex flex-column justify-content-between bg-white h-100"
        ref={refRightMainCover}
      >
        <VATContext.Provider
          value={{
            openPopupVat,
            setOpenPopupVat,
            openRemoveVat,
            setOpenRemoveVat,
          }}
        >
          <div className="l-segment flex-stretch scrollable-y">
            <div className="">
              <Customers
                showEditAddress={showEditAddress}
                setShowEditAddress={setShowEditAddress}
              />
            </div>
            {IS_OPEN_PRICING_LIST(appContext) &&
            current_shopping_cart?.cart_quantity_total > 0 ? (
              <div className="flex-auto">
                <PricingList />
              </div>
            ) : null}
            {current_shopping_cart?.lineItems?.length > 0 && isShowAction ? (
              <hr className="my-0 l-mb-8" />
            ) : null}
            {isShowAction && current_shopping_cart?.lineItems?.length > 0 ? (
              <div className="d-flex align-items-center">
                <AddressDelivery />
                {!(
                  current_refund?.taxData?.eInvoiceInfo?.name ||
                  current_refund?.taxData?.billingCompany
                ) && <VAT />}
              </div>
            ) : null}

            {current_shopping_cart?.lineItems?.length > 0 ? (
              <>
                {!appContext?.shop_setting?.hideOrderShippingState &&
                current_shopping_cart?.isShipping ? (
                  <>
                    <AddressDelivery isShippingAddress={true} />
                  </>
                ) : null}
                {shopInfo.taxable &&
                (current_refund?.taxData?.eInvoiceInfo?.name ||
                  current_refund?.taxData?.billingCompany) ? (
                  <VAT isVATInfo={true} />
                ) : null}
              </>
            ) : null}

            <div className="border-top l-mt-8">
              <PaymentSummary
                totalMoneyPaidByCustomer={totalMoneyPaidByCustomer}
                refundOrder={onRefundOrder}
                ref={refPaymentSummary}
              />
            </div>
          </div>

          <div className="l-segment flex-auto">
            <RefundPaymentBottomRight
              refundOrder={onRefundOrder}
              totalMoneyPaidByCustomer={totalMoneyPaidByCustomer}
              ref={refPaymentBottomRight}
            />
          </div>
        </VATContext.Provider>
      </div>
      {/* {popupRemoveVat()} */}
      <ModalPaymentMethods
        show={showPayment}
        setShow={setShowPayment}
        totalMoneyPaidByCustomer={totalMoneyPaidByCustomer}
        refundOrder={onRefundOrder}
        isCod={isCod}
        setShowModalPaymentPartial={setShowModalPaymentPartial}
      />
      <ModalRefundOrderSuccess
        setBlockingPage={setBlockingPage}
        show={showModalSuccess?.type}
        setShow={(value) => {
          if (!value) {
            setShowModalSuccess(null);
            setBlockingPage(false);
          }
        }}
        data={showModalSuccess?.data}
      />
      <ModalConfirmPartialPayment
        sendOrder={onRefundOrder}
        show={showModalPaymentPartial}
        setShow={setShowModalPaymentPartial}
        totalMoneyPaidByCustomer={totalMoneyPaidByCustomer}
      />
    </>
  );
};
