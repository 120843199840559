import { Assets } from "assets";

export const PaymentMethods = {
  cash: "Tiền mặt",
  visa: "Visa/Master",
  transfer: "Chuyển khoản",
  cod: "COD",
  momo: "MoMo",
  moca: "Moca",
  vnpay: "VNPAY",
  zalopay: "ZaloPay",
  online: "Thanh toán online",
};

const tooltipPaymentMethod = {
  tooltipCOD: "bằng COD",
  cash: "bằng Tiền mặt",
  transfer: "bằng Chuyển khoản",
  visa: "bằng Visa/Master",
  momo: "bằng ví Momo",
  zalopay: "bằng ví ZaloPay",
  vnpay: "bằng ví VNPAY",
  moca: "bằng ví Moca",
};

export const listPaymentMethod = (shop_setting) => {
  return {
    cash: {
      name: "Tiền mặt",
      icon: Assets.icon.pay_cash,
      isHidden: false,
      isOnly: false,
      tooltipContent: tooltipPaymentMethod.cash,
    },
    visa: {
      name: "Visa/Master",
      icon: Assets.icon.visa,
      isHidden: !shop_setting.visaGatewayEnable,
      isOnly: false,
      tooltipContent: tooltipPaymentMethod.visa,
    },
    transfer: {
      name: "Chuyển khoản",
      icon: Assets.icon.pay_bank,
      isHidden: !shop_setting.transferGatewayEnable,
      isOnly: false,
      tooltipContent: tooltipPaymentMethod.transfer,
    },
    // "cod": {
    //   name: 'COD',
    //   icon: Assets.icon.pay_cod,
    //   isHidden: !(shop_setting.codGatewayEnable && !Cart Store.isRefundMode),
    //   isOnly: false,
    //   tooltipContent: tooltipPaymentMethod.tooltipCOD
    // },
    momo: {
      name: "MoMo",
      icon: Assets.icon.pay_momo,
      isHidden: !shop_setting.momoGatewayEnable,
      isOnly: true,
      tooltipContent: tooltipPaymentMethod.momo,
    },
    moca: {
      name: "Moca",
      icon: Assets.icon.pay_moca,
      isHidden: !shop_setting.mocaGatewayEnable,
      isOnly: true,
      tooltipContent: tooltipPaymentMethod.moca,
    },
    vnpay: {
      name: "VNPAY",
      icon: Assets.icon.pay_vnpay,
      isHidden: !shop_setting.vnpayGatewayEnable,
      isOnly: true,
      tooltipContent: tooltipPaymentMethod.vnpay,
    },
    zalopay: {
      name: "ZaloPay",
      icon: Assets.icon.pay_zalopay,
      isHidden: !shop_setting.zalopayGatewayEnable,
      isOnly: true,
      tooltipContent: tooltipPaymentMethod.zalopay,
    },
  };
};
export const PayMEMethodCode = "payme";
export const PaymentPOSMethods = {
  payme: 71,
};
export const PaymentQrMethods = {
  momo_qr: 21,
  tranfer_qr: 53,
  mb_bank_qr: 54,
  vcb_qr: 55,
  vietin_bank_qr: 56,
  bidv_qr: 57,
  tcb_qr: 58,
  sacombank_qr: 59,
  acb_qr: 60,
  vp_bank_qr: 61,
  hd_bank_qr: 62,
  vib_qr: 63,
  tp_bank_qr: 64,
  scb_qr: 65,
  nam_a_bank_qr: 66,
  ocb_qr: 67,
  eximbank_qr: 68,
  se_a_bank_qr: 69,
  agribank_qr: 70,
  bv_bank_qr: 72,
  msb_qr: 73,
};
export const AutoQRPaymentBanks = {
  // 970422: {
  //   bankName: 'MB',
  //   bankCode: 970422,
  // }
};
