import Button from "component-v2/buttons";
import Logo from "component-v2/logos/logos";
import { useApp } from "contexts/app";
import { useEffect, useRef, useState } from "react";

export function RubikBody() {
  const { shop_info } = useApp();
  const [show, setShow] = useState(false);

  const this_ref = useRef(null);
  useEffect(() => {
    if (show) {
      document.addEventListener("click", handleOutsideClick, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  function handleOutsideClick(e) {
    if (!this_ref.current.contains(e.target)) {
      setShow(false);
      document.removeEventListener("click", handleOutsideClick, false);
    }
  }

  return (
    <div ref={(node) => (this_ref.current = node)} className="l-header--rubik">
      <Button plain icon="apps" size="sm" onClick={() => setShow(!show)} />
      <div className={`l-header--rubik-body${show ? " show" : ""}`}>
        <a
          href={shop_info?.seller_domain}
          target="_blank"
          rel="noreferrer"
          className="l-header--rubik-item"
        >
          <Logo name="haravan_logo" size={5.6} />
          <div>
            <label>Haravan</label>
            <span>
              Giải pháp quản lý bán hàng đa kênh và tối ưu hoạt động doanh
              nghiệp
            </span>
          </div>
        </a>
        <a
          href={`${process.env.REACT_APP_SOCIAL}/account/login?prompt=login&src=pos&orgid=${shop_info?.org_id}`}
          target="_blank"
          rel="noreferrer"
          className="l-header--rubik-item"
        >
          <Logo name="harasocial_logo" size={5.6} />
          <div>
            <label>Harasocial</label>
            <span>
              Giải pháp bán hàng, marketing và chăm sóc khách hàng qua mạng xã
              hội
            </span>
          </div>
        </a>
        <a
          href={`${process.env.REACT_APP_LEGAGY_LOY}/?src=pos&orgid=${shop_info?.org_id}`}
          target="_blank"
          rel="noreferrer"
          className="l-header--rubik-item"
        >
          <Logo name="haraloyalty_logo" size={5.6} />
          <div>
            <label>Haraloyalty</label>
            <span>
              Giải pháp chuyển đổi khách hàng mới trở thành khách hàng trung
              thành thông qua cá nhân hóa hoạt động tiếp thị trên quy mô lớn
            </span>
          </div>
        </a>
      </div>
    </div>
  );
}
