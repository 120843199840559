import React, { memo, useEffect, useState } from "react";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import { useApp } from "contexts/app";
import Icon from "component-v2/icons/icons";
import Button from "component-v2/buttons";

export default memo(function LoginRequiredModal() {
  const app = useApp();
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(app.login_required);
  }, [app.login_required]);
  const body = (
    <div className="text-center">
      <div className="l-empty-state--icon">
        <Icon size={4} name="settings" color="var(--contentTertiary)" />
      </div>
      <div className="l-mt-8 font-weight-semibold">
        Phiên đăng nhập của bạn đã hết hạn
      </div>
      <p className="l-mt-8">Bạn cần thực hiện đăng nhập lại để tiếp tục!</p>
    </div>
  );

  const footer = (
    <div className="mx-auto">
      <Button
        status="primary"
        size="md"
        onClick={() => {
          window.location.replace("/account/login?prompt=login");
        }}
        title="Đăng nhập lại"
      ></Button>
    </div>
  );

  return (
    <GlobalModal body={body} footer={footer} show={show} headerClose={false} />
  );
});
