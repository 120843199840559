import Icon from "component-v2/icons/icons";

const CouponBadge = ({
  className,
  couponLabel,
  canRemove = false,
  onClickRemove,
  badgeIcon,
  iconBackground,
  onClick
}) => {
  return (
    <div
      className={`coupon-badge-wrapper border d-flex justify-content-between align-items-center  text-truncate text-nowrap truncate-30ch ${className}`}
      style={{ height: "28px" }}
      onClick={() => onClick?.()}
    >
      {badgeIcon && (
        <div
          className="icon-wrapper h-100"
          style={{ backgroundColor: iconBackground || "var(--orange300)" }}
        >
          <Icon
            className={"d-flex align-items-center"}
            size="sm"
            name={badgeIcon}
            color={"var(--coolgray600)"}
          />
        </div>
      )}
      <span className="w-100 m-0 overflow-hidden text-truncate">
        {couponLabel}
      </span>
      {canRemove && (
        <span
          onClick={() => onClickRemove(couponLabel)}
          style={{ borderLeft: "1px dashed #dee2e6", marginLeft: "4px" }}
        >
          <Icon
            className={"close d-flex l-ml-4 align-items-center cursor-pointer"}
            size="1.6"
            name="close"
            color="black"
          />
        </span>
      )}
    </div>
  );
};

export default CouponBadge;
