import { getStaffUsers } from "api/settings-repository";

class ShopService {
  getShopInfo = async (callAPI) => {
    const result = await callAPI("get", "call/com_api/shop/checkvalid");
    const shop_info = result?.data;
    return shop_info;
  };
  tryActiveShop = async (callAPI) => {
    const response = await callAPI("post", "/call/com_api/shop", {
      channel: "hararetail",
    });
    return response?.data;
  };

  getShopSettingNew = async (callAPI, is_admin) => {
    let result = { ready: false };
    try {
      const response = await callAPI("get", `/call/retail_api/settings/info`);
      if (response?.data) {
        result = response.data;
        result.ready = true;
        return result;
      }
      // nếu là admin, update default setting
      if (response?.status === 200) {
        if (is_admin) {
          const retry = await callAPI(
            "post",
            `/call/retail_api/setting/initiate`,
          );
          if (retry?.data) {
            result = retry.data;
            result.ready = true;
          }
        }
        return result;
      }
      return null;
    } catch (_ex) {
      return null;
    }
  };

  getShopSetting = async (callAPI, is_admin) => {
    // lấy setting shop
    // nếu API lỗi => return null
    // nếu API thành công mà data rỗng => return {ready : false}
    let result = { ready: false };
    try {
      const response = await callAPI("get", `call/retail_api/settings/info`);
      if (response?.data) {
        result = response.data;
        result.ready = true;
        return result;
      }
      // nếu là admin, update default setting
      if (response?.status === 200) {
        if (is_admin) {
          const retry = await callAPI(
            "post",
            `/call/retail_api/setting/initiate`,
          );
          if (retry?.data) {
            result = retry.data;
            result.ready = true;
          }
        }
        return result;
      }
      return null;
    } catch (_ex) {
      return null;
    }
  };
  getShopLocations = async (callAPI, shop_info, user, locationId) => {
    if (!shop_info) return { locations: null, current: null };
    try {
      let locations = [];
      let current = null;
      const response = await callAPI(
        "get",
        "/call/com_api/locations",
        null,
        false,
      );
      let all_locations = response?.data;
      // nếu không có quyền truy cập => lấy data từ shop_info
      if (response?.status === 403) {
        all_locations = [];
        shop_info.locations?.forEach((_l) => {
          all_locations.push({
            id: _l.id,
            name: _l.location_name,
            locationName: _l.location_name,
            isUnavailableQty: false,
            holdStock: true,
          });
        });
      }
      if (!all_locations) return { locations: null, current: null };
      // nếu có danh sách (rỗng hoặc có data đều được)
      if (user.is_admin) {
        // full list location nếu user là owner hoặc admin
        locations = all_locations;
      } else {
        // map theo danh sách kho user được assigned
        shop_info.locations?.forEach((loc) => {
          let _location = all_locations.find((l) => l.id === loc.id);
          if (_location) locations.push(_location);
        });
      }
      const locationKey = `@haravan-pos:location:${user.id}:${user.orgid}`;
      let saved_location = localStorage.getItem(locationKey);
      if (saved_location) {
        const data = JSON.parse(saved_location);
        if (locationId && data.id !== locationId) {
          current = locations.find((_l) => _l.id === locationId);
        } else {
          current = locations.find((_l) => _l.id === data.id);
        }
      } else current = locations.find((_l) => _l.isPimaryLocation);
      if (!current && locations.length) current = locations[0];
      if (current) localStorage.setItem(locationKey, JSON.stringify(current));
      return { locations, current };
    } catch (_ex) {
      return { locations: null, current: null };
    }
  };
  getShopSettingLocations = async (callAPI, locationIds) => {
    try {
      const response = await callAPI(
        "get",
        `/call/retail_api/settings/locations?locationIds=${locationIds}`,
      );
      return response;
    } catch (error) {
      return {
        errors: error,
      };
    }
  };
  getShopSettingLocationDetail = async (callAPI, locationId) => {
    try {
      const response = await callAPI(
        "get",
        `/call/retail_api/settings/locations/${locationId}`,
      );
      return response;
    } catch (error) {
      return {
        errors: error,
      };
    }
  };
  updateShopSettingLocation = async (callAPI, model) => {
    try {
      const response = await callAPI(
        "put",
        `/call/retail_api/settings/locations`,
        model,
      );
      return response;
    } catch (error) {
      return {
        errors: error,
      };
    }
  };
  getLoyaltySetting = async (callAPI) => {
    // return data:
    // enabled
    // is_allow
    // can_use_with_others
    // enabled_refund_point
    try {
      let loyalty = {
        enabled: false,
        is_allow: false,
        can_use_with_others: true,
        enabled_refund_point: false,
      };
      const response = await callAPI(
        "get",
        `/call/loy_api/v1/organizations/info`,
      );
      if (!response || response.status >= 500) return null; // lỗi api => return null
      if (response?.status === 0) return loyalty; // shop không có loyalty
      if (response.data?.loyalty) {
        const { is_enable_loyalty, is_allow_loyalty, config } =
          response.data.loyalty;
        const response2 = await callAPI(
          "get",
          `/call/loy_api/v1/configs/refund`,
        );
        if (!response?.status === 200) return null; // lỗi api => return null
        let enabled_refund_point = false;
        if (response2.data)
          enabled_refund_point =
            response2.data.value?.is_enable_point_refund === 1;
        loyalty = {
          enabled: is_enable_loyalty === "active",
          is_allow: is_allow_loyalty === 1,
          can_use_with_others:
            config?.membership_setting?.is_use_with_others === 1,
          enabled_refund_point,
          config,
        };
      }
      return loyalty;
    } catch (error) {
      return null;
    }
  };
  getStaffUsers = async (callAPI, location_id, user) => {
    const response = await getStaffUsers(callAPI, location_id);
    let arr = response?.data;
    if (!arr) arr = [];
    let _idx = arr.findIndex((_u) => _u.id === user.id);
    if (_idx > -1) {
      arr[_idx].default = true;
    } else {
      arr.unshift({
        id: user.id,
        name: user.name,
        email: user.email,
        phone: user.phone,
        default: true,
      });
    }
    return arr;
  };
  getPricingList = async (callAPI, location_id, customer_id = null) => {
    try {
      let url = `/call/com_api/orders/cart/pricing_lists/discover?channel=pos&location_id=${location_id}`;
      if (customer_id) {
        url += `&customer_id=${customer_id}`;
      }
      const response = await callAPI("get", url);
      const data = response.data ?? [];
      return data;
    } catch (error) {
      return [];
    }
  };
}
const shopService = new ShopService();
export default shopService;
