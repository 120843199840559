import React from "react";
import "moment-timezone";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { CONSTANT_LINKS, getConstantLinkDetail } from "models/links";
import {
  DateTimeShowType,
  toFormattedCurrency,
} from "infrastructures/format-helpers";
import DateTimeShow from "component-v2/datetime-show";
import { initCustomerInfoList } from "infrastructures/utils";
import "../utils/index.css";
import Badge from "screens-v2/layouts/layouts/omni-badge";
import {
  paymentStatusValue,
  shipmentStatusValue,
} from "screens-v2/orders/utils/utils";
import Icon from "component-v2/icons/icons";
import { OrderSourceEnums } from "screens-v2/carts-v2/cart-helpers.service";

const orderSourceMapping = (sourceName) => {
  let source = OrderSourceEnums.find((source) => source.name === sourceName);
  if (source) {
    return source.display;
  } else {
    return sourceName;
  }
};

function OrderListItem({ order }) {
  const cusName =
    (order.shippingLastName ? order.shippingLastName : "") +
    " " +
    (order.shippingFirstName ? order.shippingFirstName : "");
  const { name, phone } = initCustomerInfoList(
    cusName,
    order.customerEmail,
    order.shippingPhone,
  );

  const {
    contentValue: contentValueShipment,
    badgeValue: badgeValueShipment,
    colorValue: colorIconShipment,
  } = React.useMemo(
    () => shipmentStatusValue(order.shipmentStatus, order.fulfilledStatus),
    [order],
  );
  const {
    contentValue: contentValuePayment,
    badgeValue: badgeValuePayment,
    colorValue: colorIconPayment,
  } = React.useMemo(() => paymentStatusValue(order.paymentStatusId), [order]);
  let source = orderSourceMapping(order.sourceName);
  return (
    <NavLink
      className="text-decoration-none"
      to={getConstantLinkDetail(
        CONSTANT_LINKS.route_order_detail,
        order.orderId,
      )}
    >
      <li className={`list-group-item pointer`}>
        <div className="row">
          <div className="col-6">
            <div className="order-code">
              <DateTimeShow
                className="l-text-smaller display-block"
                date={order.orderDate}
                type={DateTimeShowType.long_date}
              />
              <div className="l-mt-4 font-weight-medium text-break">
                {order.orderNumber}
              </div>
              {name ? (
                <div
                  className="text-secondary text-break l-mt-4"
                  style={{ width: "200px" }}
                >
                  {name}
                </div>
              ) : phone ? (
                <div
                  className="text-secondary text-break l-mt-4"
                  style={{ width: "200px" }}
                >
                  {phone}
                </div>
              ) : null}
              <div className="icon-w-13 text-secondary l-mt-4">{source}</div>
            </div>
          </div>
          <div className="col-6 text-right pl-0 d-flex flex-column">
            <div className="d-flex flex-column align-self-end">
              {order.omniOrderStatus === 11 ? (
                <Badge
                  className="align-self-end l-mb-4"
                  light
                  rounded
                  status={"red"}
                >
                  <FormattedMessage
                    id="orders.searches.Đã hủy"
                    defaultMessage="Đã hủy"
                  />
                </Badge>
              ) : null}
              {order.omniOrderStatus !== 11 && order.isArchived ? (
                <Badge
                  className="align-self-end l-mb-4"
                  light
                  rounded
                  status={"red"}
                >
                  <FormattedMessage
                    id="orders.searches.Lưu trữ"
                    defaultMessage="Lưu trữ"
                  />
                </Badge>
              ) : null}
              {!order.isArchived ? (
                <>
                  {contentValuePayment && (
                    <Badge
                      className="align-self-end l-mb-4"
                      light
                      rounded
                      status={badgeValuePayment}
                    >
                      <Icon
                        size="xs"
                        name={"payments"}
                        color={colorIconPayment}
                        className="mr-2"
                      />
                      {contentValuePayment}
                    </Badge>
                  )}
                  {contentValueShipment && (
                    <Badge
                      className="align-self-end l-mb-4"
                      light
                      rounded
                      status={badgeValueShipment}
                    >
                      <Icon
                        size="xs"
                        name={"local_shipping"}
                        color={colorIconShipment}
                        className="mr-2"
                      />
                      <FormattedMessage
                        id={`carts.orders.shipments.${contentValueShipment}`}
                        defaultMessage={contentValueShipment}
                      />
                    </Badge>
                  )}
                </>
              ) : null}
              <p className="order-price">
                {toFormattedCurrency(order.orderTotal)}
              </p>
            </div>
          </div>
        </div>
      </li>
    </NavLink>
  );
}
export default OrderListItem;
