import Button from "component-v2/buttons";
import { useShift } from "contexts/shift/shift-context";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";
import { toFormattedCurrency } from "infrastructures/format-helpers";
import { HOTKEYS, HOTKEY_SCOPES } from "models/constants";
import { useCartContext } from "../..";
import { useApp } from "contexts/app";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useHotkeysContext } from "react-hotkeys-hook";
import { useIntl } from "react-intl";
import { useLayoutContext } from "contexts/Layout/layout-context";
import PrintButtonTempBill from "../components/print-button-temp-bill";

export const Payment = memo(
  ({
    totalMoneyPaidByCustomer,
    isCod,
    payment_methods_active,
    setShowModalPaymentPartial,
    sendOrder,
    showModalSuccess,
    hasQRPayment,
    hasPOSPayment,
    setPaymentDetails,
  }) => {
    const { loading } = useShift();
    const intl = useIntl();
    const appContext = useApp();
    const {
      current_shopping_cart,
      loyaltyLoading,
      loadingCart,
      setShowPOSPaymentModal,
    } = useCartContext();
    const { disableScope } = useHotkeysContext();
    const { showGlobalToast } = useLayoutContext();
    const [hasModalOpen, setHasModalOpen] = useState(false);

    useEffect(() => {
      const modalRoot = document.querySelector("#root-modal-cover");
      if (modalRoot?.children?.length && !hasModalOpen) {
        setHasModalOpen(true);
      } else if (!modalRoot?.children?.length && hasModalOpen) {
        setHasModalOpen(false);
      }
    });
    const activePOSMethod = useMemo(() => {
      return current_shopping_cart?.payment_methods?.find(
        (method) => method.enable && method.isPOS,
      );
    }, [current_shopping_cart?.payment_methods]);
    const showPosPayment = () => {
      if (!current_shopping_cart?.phone) {
        showGlobalToast(
          "default",
          intl.formatMessage({
            id: "notifications.Bắt buộc nhập số điện thoại của khách hàng để thanh toán qua PayME",
            defaultMessage:
              "Bắt buộc nhập số điện thoại của khách hàng để thanh toán qua PayME",
          }),
        );
        return;
      }
      setPaymentDetails({
        orderData: current_shopping_cart,
        qrStatus: "pending",
        sessionId: current_shopping_cart.id,
        paymentInstructionId: activePOSMethod?.paymentInstructionId,
        paymentMethodId: activePOSMethod?.paymentInstructionTypeId,
        paymentMethodName: activePOSMethod.name,
        paymentMethodType: activePOSMethod.type,
        amount: activePOSMethod?.amount,
      });
      setShowPOSPaymentModal(true);
    };
    useGlobalHotkeys({
      keys: [HOTKEYS.F9],
      callback: (e) => {
        disableScope(HOTKEY_SCOPES.CART);
        if (hasPOSPayment) {
          showPosPayment();
          return;
        }
        if (
          totalMoneyPaidByCustomer <
            current_shopping_cart?.summaries?.total_payment &&
          !isCod &&
          payment_methods_active?.length > 0
        ) {
          setShowModalPaymentPartial(true);
        } else {
          sendOrder();
        }
      },
      enabled:
        payment_methods_active?.length > 0 &&
        current_shopping_cart?.lineItems?.length > 0 &&
        !loyaltyLoading &&
        !loadingCart,
      scopes: HOTKEY_SCOPES.CART,
    });

    if (!current_shopping_cart?.summaries) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="l-pt-4">
          <Button
            className="w-100"
            onClick={() => {
              if (loadingCart) {
                showGlobalToast(
                  "default",
                  intl.formatMessage({
                    id: "notifications.Đang cập nhật giỏ hàng",
                    defaultMessage: "Đang cập nhật giỏ hàng",
                  }),
                );
                return;
              }
              if (hasPOSPayment) {
                showPosPayment();
                return;
              }
              if (
                totalMoneyPaidByCustomer <
                  current_shopping_cart?.summaries?.total_payment &&
                !isCod &&
                payment_methods_active?.length &&
                !hasQRPayment
              ) {
                setShowModalPaymentPartial(true);
              } else {
                !showModalSuccess && sendOrder();
              }
            }}
            loading={loading}
            badge={HOTKEYS.F9}
            status="primary"
            size="lg"
            disabled={
              !payment_methods_active?.length ||
              !current_shopping_cart?.lineItems?.length ||
              loyaltyLoading ||
              hasModalOpen
            }
            title={
              isCod || !totalMoneyPaidByCustomer
                ? "Đặt hàng"
                : hasQRPayment
                  ? `Quét mã QR`
                  : `Thanh toán ${toFormattedCurrency(
                      totalMoneyPaidByCustomer >
                        current_shopping_cart?.summaries.total_payment
                        ? current_shopping_cart?.summaries.total_payment
                        : totalMoneyPaidByCustomer,
                    )}`
            }
            icon={hasQRPayment && !!totalMoneyPaidByCustomer && `qrCode`}
          />
        </div>
        <div className="l-pt-8 d-flex gap-8">
          {appContext?.shop_setting?.printSettings?.allowPrintTempBill && (
            <div className="w-100">
              <PrintButtonTempBill
                intl={intl}
                isCod={isCod}
                isBtnLoading={false}
                isShowTitleButton
                hasTitle
                size="lg"
                disabled={
                  !current_shopping_cart?.lineItems?.length ||
                  loadingCart ||
                  loyaltyLoading ||
                  hasModalOpen
                }
                taxData={current_shopping_cart?.taxData}
              ></PrintButtonTempBill>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  },
);
