import Button from "component-v2/buttons";
import { LAST_USED_POS_DEVICE, useApp } from "contexts/app";
import { useEffect, useRef, useState } from "react";
import GlobalModal from "screens-v2/layouts/layouts/global-modal";
import Logo from "component-v2/logos/logos";
import { callGetApiOrderDetails } from "api/order-repository";
import { CONSTANT_LINKS, getConstantLinkDetail } from "models/links";
import Icon from "component-v2/icons/icons";
import { useLayoutContext } from "contexts/Layout/layout-context";
import { useIntl } from "react-intl";
import { toNumber } from "lodash";
import { toFormattedCurrency } from "infrastructures/format-helpers";
import Tooltip from "rc-tooltip";
import { HOTKEY_EVENTS, HOTKEY_SCOPES, HOTKEYS } from "models/constants";
import { getCheckoutSession, repaymentCheckout } from "api/checkout-repository";
import { ReactComponent as PaymentTypeCard } from "assets/icons/payment_type_card.svg";
import { ReactComponent as PaymentTypeQR } from "assets/icons/payment_type_qr.svg";
import { ReactComponent as PaymentTypeCardFull } from "assets/icons/payment_type_card_full.svg";
import { ReactComponent as PaymentTypeQRFull } from "assets/icons/payment_type_qr_full.svg";

import { SelectionCustom } from "./SelectionCustom";
import useGlobalHotkeys from "hooks/useGlobalHotkeys";

const POSStatus = {
  loading: "loading",
  success: "success",
  error: "error",
  expired: "expired",
  pending: "pending",
};
const CheckoutPaymentStatus = {
  authorized: "Authorized",
  paid: "Paid",
  partially_refunded: "PartiallyRefunded",
  partially_paid: "PartiallyPaid",
  pending: "Pending",
  refunded: "Refunded",
  unpaid: "Unpaid",
  voided: "Voided",
};
const paymeMethods = {
  POS: 1,
  QR: 2,
};
export const ModalPOSPayment = ({
  show,
  setShow,
  paymentDetails,
  setPaymentDetails,
  changeMethodAction,
  cancelOrderAction,
  onTransactionCompleted,
  signalRData,
  sendOrder,
}) => {
  const { callAPI, shop_setting, location_devices } = useApp();
  const { showGlobalToast } = useLayoutContext();
  const intl = useIntl();
  const [qrStatus, setQrStatus] = useState(
    paymentDetails?.qrStatus || "loading",
  );
  const [showNextStep, setShowNextStep] = useState(false);
  const lastUsedDevice = location_devices?.find(
    (device) => device.id === localStorage.getItem(LAST_USED_POS_DEVICE),
  )?.id;
  const [selectedDevice, setSelectedDevice] = useState(
    lastUsedDevice || location_devices?.[0]?.id,
  );
  const [selectedMethod, setSelectedMethod] = useState(paymeMethods.POS);
  const [loading, setLoading] = useState(false);
  const showRef = useRef(show);
  const checkStatusRef = useRef(null);
  const paymentDetailsRef = useRef(paymentDetails);
  showRef.current = show;
  paymentDetailsRef.current = paymentDetails;

  const getOrder = async () => {
    const res = await callGetApiOrderDetails(
      callAPI,
      paymentDetailsRef.current?.orderId,
    );
    if (res && res?.data) {
      const { transactions, noteAttributes } = res?.data;
      let newOrderData = { ...paymentDetails?.orderData, ...res?.data };
      newOrderData.noteAttributes = noteAttributes || [];
      newOrderData.transactions = transactions || [];
      onTransactionCompleted?.({
        ...newOrderData,
        paymentStatus: "paid",
        isQRPayment: true,
      });
    } else {
      const errorMsg =
        res?.errors?.[0] || "Đã có lỗi xảy ra, xin vui lòng thử lại.";
      showGlobalToast(
        "error",
        intl.formatMessage({
          id: `notifications.${errorMsg}`,
          defaultMessage: errorMsg,
        }),
      );
    }
  };
  const confirmPayment = () => {
    setLoading(true);
    localStorage.setItem(LAST_USED_POS_DEVICE, selectedDevice);
    const device = location_devices?.find((d) => d.id === selectedDevice);
    if (paymentDetails.orderId) {
      renewPaymentLink(device?.deviceName);
      return;
    }
    sendOrder({
      paymentDeviceName: device?.deviceName,
      posPaymentType: selectedMethod,
      posTransaction: {
        paymentInstructionId: paymentDetails?.paymentInstructionId,
        paymentMethodId: paymentDetails?.paymentMethodId,
        paymentMethodName: paymentDetails.name,
        paymentMethodType: paymentDetails.type,
      },
    });
  };
  useGlobalHotkeys({
    keys: [HOTKEYS.ESC, HOTKEYS.F9],
    callback: (e) => {
      if (e.key === HOTKEY_EVENTS.ESC) {
        setShow(false);
      }
      if (e.key === HOTKEY_EVENTS.F9) {
        confirmPayment();
      }
    },
    enabled: show,
    scopes: HOTKEY_SCOPES.MODAL,
  });
  useEffect(() => {
    if (
      signalRData &&
      signalRData?.order &&
      signalRData?.order?.id === paymentDetailsRef?.current?.orderData?.orderId
    ) {
      const { paymentStatus } = signalRData?.order;
      if (paymentStatus === CheckoutPaymentStatus.paid) {
        getOrder();
      } else if (
        paymentStatus === CheckoutPaymentStatus.unpaid ||
        paymentStatus === CheckoutPaymentStatus.voided
      ) {
        onTransactionCompleted?.({
          ...paymentDetails?.orderData,
          paymentStatus: "failed",
          isQRPayment: true,
        });
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDetails?.orderData, signalRData]);

  const checkTransactionStatus = async (interval = false) => {
    if (showRef.current === false) {
      if (!!checkStatusRef.current) {
        clearTimeout(checkStatusRef.current);
        checkStatusRef.current = null;
      }
      return;
    }
    return await getCheckoutSession(
      callAPI,
      paymentDetailsRef.current?.sessionId,
    )
      .then(async (res) => {
        if (
          res &&
          res?.order &&
          res?.order?.id === paymentDetailsRef?.current?.orderData?.orderId
        ) {
          const { paymentStatus } = res?.order;
          if (paymentStatus === CheckoutPaymentStatus.paid) {
            getOrder();
          } else if (
            paymentStatus === CheckoutPaymentStatus.unpaid ||
            paymentStatus === CheckoutPaymentStatus.voided
          ) {
            onTransactionCompleted?.({
              ...paymentDetails?.orderData,
              paymentStatus: "failed",
              isQRPayment: true,
            });
          } else if (interval) {
            if (!!checkStatusRef.current) {
              clearTimeout(checkStatusRef.current);
              checkStatusRef.current = null;
            }
            checkStatusRef.current = setTimeout(() => {
              checkTransactionStatus(true);
            }, 5000);
          }
        } else {
          const errorMsg =
            res?.errors?.[0] || "Đã có lỗi xảy ra, xin vui lòng thử lại.";
          showGlobalToast(
            "error",
            intl.formatMessage({
              id: `notifications.${errorMsg}`,
              defaultMessage: errorMsg,
            }),
          );
        }
      })
      .catch((err) => {
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.${"Đã có lỗi xảy ra, xin vui lòng thử lại."}`,
            defaultMessage: "Đã có lỗi xảy ra, xin vui lòng thử lại.",
          }),
        );
      });
  };
  useEffect(() => {
    if (show && paymentDetails && paymentDetails?.orderData) {
      setLoading(false);
      setQrStatus(paymentDetails?.qrStatus);
      if (paymentDetails.qrStatus === POSStatus.success) {
        setShowNextStep(true);
        checkStatusRef.current = setTimeout(() => {
          checkTransactionStatus(true);
        }, 5000);
      }
      if (paymentDetails.qrStatus === POSStatus.error) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, paymentDetails]);
  const renewPaymentLink = async (deviceName) => {
    await repaymentCheckout(callAPI, paymentDetails?.sessionId, {
      paymentInstructionId: paymentDetails?.paymentInstructionId,
      paymentMethodId: paymentDetails?.paymentMethodId,
      deviceUserName: deviceName,
      posPaymentType: selectedMethod,
    }).then((res) => {
      const posData = res?.data?.nextActions?.find(
        (action) => action.type === "Device",
      );
      if (!!posData) {
        setPaymentDetails((prev) => ({
          ...prev,
          url: "success",
          qrStatus: "success",
        }));
      } else {
        setPaymentDetails((prev) => ({ ...prev, qrStatus: "error" }));
        const errorMsg =
          res?.errors?.[0] || "Đã có lỗi xảy ra, xin vui lòng thử lại.";
        showGlobalToast(
          "error",
          intl.formatMessage({
            id: `notifications.${errorMsg}`,
            defaultMessage: errorMsg,
          }),
        );
      }
    });
  };
  useEffect(() => {
    if (!show) {
      setLoading(false);
      setShowNextStep(false);
    }
  }, [show]);

  const redirectChangePaymentMethod = () => {
    if (paymentDetails?.orderId) {
      window.open(
        `${getConstantLinkDetail(CONSTANT_LINKS.route_order_detail, paymentDetails?.orderId)}?confirmPayment=true`,
        "_blank",
        "noopener,noreferrer",
      );
    }
  };
  const redirectCancelOrder = () => {
    if (paymentDetails?.orderId) {
      window.open(
        `${getConstantLinkDetail(CONSTANT_LINKS.route_order_detail, paymentDetails?.orderId)}?cancelOrder=true`,
        "_blank",
        "noopener,noreferrer",
      );
    }
  };
  const body = () => {
    return (
      <div className="modal-payme">
        <div
          className={`modal-qr-wrapper d-flex flex-column align-items-center`}
        >
          <div
            className={`modal-qr-content d-flex flex-column align-items-center ${showNextStep ? "background-secondary" : ""}`}
          >
            {!showNextStep ? (
              <>
                <span className="logo-span">
                  <Logo
                    name={`${"payme_full"}`}
                    size={"2.2"}
                    className="d-flex width-fit-content"
                  />
                </span>
                <div className="w-100 l-mb-16">
                  <span className="font-size-16 font-weight-bold">
                    Thiết bị
                  </span>
                  <div className="w-100 l-mt-8">
                    <SelectionCustom
                      value={selectedDevice}
                      options={location_devices}
                      idField="id"
                      valueField="deviceName"
                      onChange={(value) => {
                        if (value !== selectedDevice) {
                          setSelectedDevice(value);
                        }
                      }}
                      style={{ width: "100%" }}
                      prefix={
                        <div
                          className={`d-flex justify-content-center align-items-center l-mr-8 background-tertiary rounded-circle p-1`}
                          style={{ minWidth: "40px", height: "40px" }}
                        >
                          <Icon name={"payment_device"} />
                        </div>
                      }
                    >
                      {location_devices?.map((u) => {
                        return (
                          <SelectionCustom.Option key={u.id} value={u.id}>
                            <div className="d-flex align-items-center">
                              {u.deviceName}
                            </div>
                          </SelectionCustom.Option>
                        );
                      })}
                    </SelectionCustom>
                  </div>
                </div>
                <div className="container w-100 p-0">
                  <span className="font-size-16 font-weight-bold">
                    Phương thức thanh toán
                  </span>
                  <div className="row l-pl-16 l-pr-16 l-mt-8">
                    <div className="col-6 pl-0 l-pr-8">
                      <div
                        onClick={() => setSelectedMethod(paymeMethods.POS)}
                        className={`payme-method-option ${selectedMethod === paymeMethods.POS ? "active" : ""} d-flex flex-column align-items-center justify-content-center l-p-16 cursor-pointer`}
                      >
                        <div className="icon l-mb-16">
                          <PaymentTypeCard />
                        </div>
                        <span>Quẹt thẻ</span>
                      </div>
                    </div>
                    <div className="col-6 pr-0 l-pl-8">
                      <div
                        onClick={() => setSelectedMethod(paymeMethods.QR)}
                        className={`payme-method-option ${selectedMethod === paymeMethods.QR ? "active" : ""} l-pl-8 d-flex flex-column align-items-center justify-content-center l-p-16 cursor-pointer`}
                      >
                        <div className="icon l-mb-16">
                          <PaymentTypeQR />
                        </div>
                        <span>Quét mã QR</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <span className="logo-span bg-white">
                  <Logo
                    name={`${"payme_full"}`}
                    size={"2.2"}
                    className="d-flex width-fit-content"
                  />
                </span>
                <p
                  className="m-0 content-secondary mt-5 mb-2"
                  style={{ letterSpacing: 1 }}
                >
                  Vui lòng tiếp tục trên thiết bị PayME
                </p>
                <div className="w-100 l-mb-16">
                  <div
                    className="w-100 l-mt-8 d-flex justify-content-center align-items-center"
                    style={{ height: "140px" }}
                  >
                    {selectedMethod === paymeMethods.POS ? (
                      <PaymentTypeCardFull />
                    ) : (
                      <PaymentTypeQRFull />
                    )}
                  </div>
                </div>
                <div className="d-flex l-mb-8 d-flex flex-column align-content-center justify-content-center">
                  <p className="m-0 content-secondary text-center">
                    Đơn hàng {paymentDetails?.orderNumber}
                  </p>
                  <p
                    className="l-mb-8 l-mt-8 content-primary font-weight-bold text-center font-size-18"
                    style={{ fontSize: "18px" }}
                  >
                    {!!paymentDetails?.amount &&
                      toFormattedCurrency(
                        Math.abs(toNumber(paymentDetails?.amount)),
                      )}
                  </p>
                  <Tooltip
                    placement="top"
                    showArrow={true}
                    overlay={
                      <>
                        Nếu khách đã thanh toán, bấm để kiểm tra trạng thái
                        nhanh
                      </>
                    }
                  >
                    <div
                      className="position-relative d-flex justify-content-center align-items-center mb-5"
                      onClick={async () => {
                        if (qrStatus === POSStatus.loading || loading) return;
                        setLoading(true);
                        await checkTransactionStatus().finally(() =>
                          setLoading(false),
                        );
                      }}
                    >
                      <Icon
                        name={"refresh_dual"}
                        size={"2.3"}
                        className={`refresh-dual absolute cursor-pointer ${loading ? "active" : ""} cursor-pointer`}
                      />
                      <span className="l-ml-4 cursor-pointer font-weight-bold font-size-16 content-accent">
                        Kiểm tra thanh toán
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </>
            )}
          </div>
          {!showNextStep && (
            <>
              <Button
                className="w-100 l-mt-16"
                onClick={() => confirmPayment()}
                badge={HOTKEYS.F9}
                status="primary"
                size="lg"
                disabled={!selectedDevice || loading}
                title={`Thanh toán${" "}${toFormattedCurrency(paymentDetails?.amount)}`}
              />
            </>
          )}
        </div>
        <div className="l-mt-16 d-flex flex-column">
          <div className="d-flex">
            {shop_setting?.allowCancelOrder ? (
              <Button
                title={"Hủy đơn hàng"}
                id="order.create.success_popup.new_button"
                onClick={
                  cancelOrderAction
                    ? () => cancelOrderAction(paymentDetails)
                    : () => redirectCancelOrder()
                }
                light
                size="lg"
                className="l-mr-8 w-100 font-weight-semibold font-size-16"
                disabled={!paymentDetails?.orderId}
              ></Button>
            ) : (
              <Button
                title={"Tạo đơn mới"}
                id="order.create.success_popup.new_button"
                onClick={() => setShow(false, paymentDetails, false, false)}
                light
                size="lg"
                className="l-mr-8 w-100 font-weight-semibold font-size-16"
                badge={HOTKEYS.ESC}
                disabled={!paymentDetails?.orderId}
              ></Button>
            )}
            <Button
              title={"Đổi phương thức khác"}
              id="order.create.success_popup.new_button"
              onClick={
                changeMethodAction
                  ? () => changeMethodAction(paymentDetails)
                  : () => redirectChangePaymentMethod()
              }
              light
              disabled={!paymentDetails?.orderId}
              size="lg"
              className="l-ml-8 w-100 font-weight-semibold font-size-16"
            ></Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <GlobalModal
      headerTitle={"Thanh toán PayME"}
      close
      isTitleCenter
      body={body()}
      size={"sm"}
      show={show}
      setShow={(value) => setShow(value, paymentDetails)}
      className={`modal-payme-dialog ${showNextStep ? "padding" : ""}`}
      onCancel={() => setShow(false, paymentDetails, false, false)}
      footer={<></>}
    />
  );
};
